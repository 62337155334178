var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),(_vm.storeDetails.storeName !== undefined)?_c('div',{staticClass:"mx-1 my-5 d-flex align-items-start justify-content-between"},[_c('b-col',{class:{ custom_shadow: !_vm.isDarkMode, 'box-shadow2 dark-div rounded': _vm.isDarkMode },attrs:{"lg":"3","sm":"12"}},[_c('div',{staticClass:"d-flex my-3 justify-content-around align-items-center"},[_c('div',[_vm._v(" Store Status ")]),_c('div',[_c('b-button',{staticClass:"box-shadow2",attrs:{"variant":_vm.isCriticalResponseSelected === true
                ? 'danger'
                : _vm.overallPercentage >= 80
                ? 'success'
                : _vm.overallPercentage < 80 && _vm.overallPercentage > 50
                ? 'warning'
                : 'danger'}},[_vm._v(" "+_vm._s(_vm.isCriticalResponseSelected === true ? 'Red' : _vm.overallPercentage >= 80 ? 'Green' : _vm.overallPercentage < 80 && _vm.overallPercentage > 50 ? 'Yellow' : 'Red')+" ")])],1)]),_c('div',{staticClass:"table-responsive-md"},[_c('table',{class:{
            'table table-hover custom-table': !_vm.isDarkMode,
            'table text-light custom-table': _vm.isDarkMode
          }},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Store Name")]),_c('td',[_vm._v(_vm._s(_vm.storeDetails.storeName !== undefined ? _vm.storeDetails.storeName : ''))])]),_c('tr',[_c('td',[_vm._v("Store Manager")]),_c('td',[_vm._v(_vm._s(_vm.storeDetails.storeManagerName !== undefined ? _vm.storeDetails.storeManagerName : ''))])]),_c('tr',[_c('td',[_vm._v("Audit Date")]),_c('td',[_vm._v(_vm._s(_vm.auditDetails.auditDate !== undefined ? _vm.auditDetails.auditDate : ''))])]),_c('tr',[_c('td',[_vm._v("Auditor")]),_c('td',[_vm._v(_vm._s(_vm.auditDetails.auditor !== undefined ? _vm.auditDetails.auditor : ''))])])])])])]),_c('b-col',{class:{ 'p-2 mx-2 custom_shadow': !_vm.isDarkMode, 'box-shadow2 dark-div rounded': _vm.isDarkMode },attrs:{"lg":"3","sm":"12"}},[_c('div',{staticClass:"d-flex my-3 justify-content-around align-items-center"},[_c('div',{staticClass:"h6"},[_vm._v(" Region ")]),_c('div',{staticClass:"h6"},[_vm._v(_vm._s(_vm.storeDetails.region))])]),_c('div',{staticClass:"table-responsive-md"},[_c('table',{class:{
            'table table-bordered table-hover': !_vm.isDarkMode,
            'table table-bordered text-light': _vm.isDarkMode
          }},[_c('tbody',[_c('tr',{},[_c('td',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v("Grading")])]),_c('tr',[_c('td',[_vm._v("Green")]),_c('td',[_vm._v(">= 80%")])]),_c('tr',[_c('td',[_vm._v("Yellow")]),_c('td',[_vm._v("50% - 79%")])]),_c('tr',[_c('td',[_vm._v("Red")]),_c('td',[_vm._v(" -> 50%")])])])])])]),_c('b-col',{class:{ custom_shadow: !_vm.isDarkMode, 'box-shadow2 dark-div rounded': _vm.isDarkMode },attrs:{"lg":"5","sm":"12"}},[_c('div',{staticClass:"d-flex my-3 justify-content-around align-items-center"},[_c('div',{staticClass:"h6"},[_vm._v(" Score ")]),_c('div',{staticClass:"h6"},[_c('b-button',{staticClass:"btn-lg box-shadow2",attrs:{"variant":_vm.isCriticalResponseSelected === true
                ? 'danger'
                : _vm.overallPercentage >= 80
                ? 'success'
                : _vm.overallPercentage < 80 && _vm.overallPercentage > 50
                ? 'warning'
                : 'danger'}},[_vm._v(" "+_vm._s(_vm.overallPercentage)+" %")])],1)]),_c('div',{staticClass:"table-responsive-md table-wrap"},[_c('table',{class:{
            'table table-bordered table-hover': !_vm.isDarkMode,
            'table table-bordered text-light': _vm.isDarkMode
          }},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("#")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Achievable")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Achieved")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Percent")])])]),_c('tbody',_vm._l((_vm.reportScoreCard),function(item,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(key))]),_c('td',[_vm._v(_vm._s(item.totalWeightAge))]),_c('td',[_vm._v(_vm._s(item.totalScore))]),_c('td',[_vm._v(_vm._s(item.percentage === 100 || item.percentage === 0 ? item.percentage : item.percentage.toFixed(2)))])])}),0),_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Total")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.totalWeightAge))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.totalScore))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.overallPercentage))])])])])])])],1):_vm._e(),_c('table-box',{attrs:{"checklistName":_vm.checklistName,"columns":_vm.columns,"rows":_vm.finalCheckpoints ? _vm.finalCheckpoints : [],"categoriesAndDepartments":_vm.categoriesAndDepartments,"format":this.$route.query.type,"ID":this.$route.params.id,"checklistStores":_vm.checklistStores,"roleOptions":_vm.roleOptions,"emptyText":"no checkpoint found","uploadText":"Upload Checkpoints","onlySavedReport":_vm.onlySavedReport}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"page-title text-center"},[_vm._v("Report")])])}]

export { render, staticRenderFns }