<template>
  <div>
    <h1 class="page-title text-center">{{ formatType !== 'Review' ? 'My Reports' : formatType }}</h1>
    <table-box
      :columns="columnsForDraft"
      :rows="draftedChecklists"
      :baseUrl="baseUrl"
      :loading="loading"
      emptyText="No checklist found"
      :showAdd="false"
      @open="onView"
    >
    </table-box>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import TableBox from '@/components/TableBox.vue';
import moment from 'moment';

export default {
  name: 'DraftChecklistsV2',
  components: { TableBox },
  data() {
    return {
      columnsForDraft: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'rowNum',
          width: '4%'
        },
        {
          type: 'string',
          headerText: 'CheckList Name',
          textKey: 'checklistName',
          class: { 'text-center': true },
          width: '25%'
        },
        {
          type: 'string',
          headerText: 'Status',
          textKey: 'completed',
          class: { 'text-center': true },
          width: '13%'
        },
        {
          type: 'string',
          headerText: 'Store Name',
          textKey: 'storeName',
          class: { 'text-center': true },
          width: '13%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          textKey: 'storeid',
          class: { 'text-center': true },
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Audit Date',
          class: { 'text-center': true },
          textKey: 'auditDate',
          width: '13%'
        },
        {
          type: 'string',
          headerText: 'Overall Score',
          class: { 'text-center': true },
          textKey: 'overallScore',
          width: '12%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '10%',
          functions: {
            open: true
          }
        }
      ],
      formatType: '',
      draftedChecklists: [],
      CHECKLIST_FREQUENCY: Object.freeze({
        'ONE-TIME': {
          unit: 'years',
          quantity: 100
        },
        DAILY: {
          unit: 'days',
          quantity: 1
        },
        WEEKLY: {
          unit: 'days',
          quantity: 7
        },
        BIWEEKLY: {
          unit: 'days',
          quantity: 14
        },
        MONTHLY: {
          unit: 'months',
          quantity: 1
        },
        'HALF-YEARLY': {
          unit: 'months',
          quantity: 6
        },
        YEARLY: {
          unit: 'years',
          quantity: 1
        }
      })
    };
  },
  mounted() {
    this.formatType = this.$route.query.format;
    if (this.$route.query.format === 'Review') {
      this.reviewChecklists(router);
    } else {
      this.getUserFilledReport(router);
      if (this.storeSelected !== '' || this.storeSelected || undefined) {
        this.list({ storeId: this.storeSelected });
      }
    }
  },
  methods: {
    ...mapActions('checklistFill', ['userReports', 'selectStore', 'getUserFilledReport']),
    ...mapActions('checklistV2Fill', ['list', 'selectStore', 'getUserFilledReport', 'reviewChecklists']),
    ...mapActions('cstSubmission', ['getStoreByUser']),
    onView({ row }) {
      if (row.completed === 'Submitted - 100 %') {
        router.push({
          path: `/reportFormV2/${row.id}`,
          query: { type: 'report', checklistID: row.checklist, from: 'released' }
        });
      } else {
        router.push({
          path: `/checklistV2_Fill/${row.id}`,
          query: { type: 'report', checklistID: row.checklistID }
        });
      }
    },
    getReportsToShow({ selectedStore, userSavedReports, checklists }) {
      const reports = userSavedReports
        // .filter(u => u.storeid === selectedStore)
        .map((u, index) => {
          try {
            const foundChecklist = checklists.find(c => u?.checklist._id === c.id);
            if (foundChecklist) {
              const freq = this.CHECKLIST_FREQUENCY[foundChecklist.frequency];
              let hasExpired = false;
              if (freq) {
                hasExpired = moment(u.createdAt).add(freq.quantity, freq.unit).isSameOrBefore(moment(), 'day');
              }
              return {
                ...foundChecklist,
                rowNum: index + 1,
                id: u._id,
                createdAt: u.createdAt,
                hasExpired,
                auditDate: u.auditDate,
                checklistName: u?.checklist?.name,
                checklistID: u?.checklist._id,
                completed: `${u.completed ? 'Submitted' : u.completed} - ${
                  u.overallProgress === -1 ? 'NA' : u.overallProgress * 100
                } ${'%'}`,
                //     i.reportClosureDate !== null
                // ? 'Report Closed'
                // : i.completed === true
                // ? 'Report Released'
                // : i.sendReportTo === null
                // ? `${i.completed} - ${i.overallProgress === -1 ? 'NA' : (i.overallProgress * 100).toFixed(0)} ${'%'}`
                // : `Report Send to ${i.sendReportTo} for Review `

                storeid: u.storeid,
                storeName: u?.store?.storeName,
                overallScore: u.overallScore,
                updatedAt: moment(u.updatedAt).format('YYYY-MM-DD hh:mm A')
              };
            }
          } catch (e) {
            console.error(`userSavedReports has error(s) in ${u.id}`, e.message);
          }
          return null;
        })
        .filter(u => u !== null);
      return reports;
    }
  },
  computed: {
    ...mapState('checklistFill', ['loading', 'baseUrl', 'availableChecklists', 'storeSelected']),
    ...mapState('checklistV2Fill', [
      'loading',
      'baseUrl',
      'userFilledReport',
      'storeSelected',
      'checklistsV2',
      'reviewChecklistsV2'
    ]),
    ...mapState('cstSubmission', ['store']),
    report() {
      if (this.formatType === 'Submitted') {
        return this.userFilledReport
          .filter(i => i.completed === 'True')
          .map(i => ({
            ...i,
            completed: `${i.completed} - ${i.overallProgress === -1 ? 'NA' : i.overallProgress * 100} ${'%'}`
          }));
      }
      return this.userFilledChecklists
        .filter(i => i.completed === 'False' && i.storeid === this.storeSelected)
        .map(i => ({
          ...i,
          completed: `${i.completed} - ${i.overallProgress === -1 ? 'NA' : i.overallProgress * 100} ${'%'}`
        }));
    }
  },
  watch: {
    reviewChecklistsV2(newValue) {
      if (newValue.length > 0) {
        this.draftedChecklists = newValue
          .filter(i => i.completed === false)
          .map((i, z) => ({
            ...i,
            rowNum: z + 1,
            id: i._id,
            overallScore: (i.overallScore * 100).toFixed(0),
            storeName: i.store.storeName,
            checklistID: i.checklist,
            completed: `Pending for Review`,
            updatedAt: moment(i.updatedAt).format('YYYY-MM-DD hh:mm a')
          }));
      }
    },
    checklistsV2(newValue) {
      const tempDraftedChecklists = this.getReportsToShow({
        selectedStore: this.storeSelected,
        userSavedReports: this.userFilledReport.filter(item => item.sendReportTo === null),
        checklists: newValue
      });
      this.draftedChecklists = tempDraftedChecklists.map((i, z) => ({
        ...i,
        auditDate: moment(i.auditDate).format('YYYY-MM-DD'),
        overallScore: (i.overallScore * 100).toFixed(0)
      }));
    }
  }
};
</script>
