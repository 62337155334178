<template>
  <div>
    <h1 class="page-title text-center">Todo - Checklists </h1>

    <b-form-group id="group-products" label-for="input-products">
      <template v-slot:label>
        Select Store ID
        <span class="text-danger">*</span>
      </template>
      <multiselect
        :class="{
          'box-shadow3': !isDarkMode,
          darkMode: isDarkMode
        }"
        ref="multiselect"
        tag-placeholder="Add this as new tag"
        placeholder="Select Store Id"
        track-by="value"
        v-model="selectedStore"
        :options="stores"
      ></multiselect>
    </b-form-group>

    <checklist-box
      :columns="columns"
      :showAdd="true"
      :showDownload="true"
      :rows="todayToDo"
      :loading="loading"
      addText="Draft checklists"
      downloadText="Submitted checklists"
      emptyText="Please select the store first"
      @fill-checklist="onFillChecklist"
      @add="onAdd"
      @download="onSubmitted"
    ></checklist-box>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import ChecklistBox from '@/components/ChecklistBox.vue';
export default {
  name: 'FillChecklist',
  components: { ChecklistBox, Multiselect },
  mounted() {
    this.getStoreByUser();
    const localStore = localStorage.getItem('storeId') || '';
    this.selectedStore = localStore;
    this.selectStore({ storeId: localStore });
    this.listReport({
      query: {
        startdate: moment().format('YYYY-MM-DD'),
        enddate: moment().format('YYYY-MM-DD')
      }
    });
    this.userReports();
  },
  data() {
    return {
      selectedStore: '',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'index',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Checklist Name',
          class: { 'text-center': true },
          textKey: 'name',
          width: '36%'
        },
        {
          type: 'string',
          headerText: 'Frequency',
          class: { 'text-center': true },
          textKey: 'frequency',
          width: '15%'
        },
        {
          type: 'string',
          headerText: 'Active Till',
          class: { 'text-center': true },
          textKey: 'activeTillTime',
          width: '20%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '24%',
          functions: {
            fill: true
          }
        }
      ],
      canFillChecklists: [],
      stores: [],
      todayToDo: [],
      CHECKLIST_FREQUENCY: Object.freeze({
        'ONE-TIME': {
          unit: 'years',
          quantity: 100
        },
        DAILY: {
          unit: 'days',
          quantity: 1
        },
        WEEKLY: {
          unit: 'days',
          quantity: 7
        },
        BIWEEKLY: {
          unit: 'days',
          quantity: 14
        },
        MONTHLY: {
          unit: 'months',
          quantity: 1
        },
        'HALF-YEARLY': {
          unit: 'months',
          quantity: 6
        },
        YEARLY: {
          unit: 'years',
          quantity: 1
        }
      })
    };
  },
  methods: {
    ...mapActions('checklistFill', ['list', 'userReports', 'selectStore']),
    ...mapActions('cstSubmission', ['getStoreByUser']),
    ...mapActions('report', ['listReport', 'download']),
    onFillChecklist({ row }) {
      router.push(`/checklist-fill/${row.id}`);
    },
    onView({ row }) {
      router.push(`/checklist-fill/${row.id}`);
    },
    onAdd() {
      router.push({ path: `/draft-checklists`, query: { format: 'Draft' } });
    },
    onSubmitted() {
      router.push({ path: `/draft-checklists`, query: { format: 'Submitted' } });
    },
    getLatestReport(reports) {
      const latestReport = reports.reduce((acc, curr) => {
        if (new Date(curr.createdAt).getTime() > new Date(acc.createdAt).getTime()) {
          return curr;
        }
        return acc;
      }, reports[0]);

      return latestReport;
    },
    getChecklistsToShow({ selectedStore, userSavedReports, checklists }) {
      if (userSavedReports.length === 0) {
        return checklists;
      } else {
        return checklists
          .filter(c => {
            try {
              const matchingReports = userSavedReports.filter(
                u => u?.checklistID === c.id && u.storeid.toString() === selectedStore.toString()
              );

              const latestReport = this.getLatestReport(matchingReports);
              if (latestReport) {
                const freq = this.CHECKLIST_FREQUENCY[c.frequency];
                if (freq) {
                  return moment(latestReport.createdAt)
                    .add(freq.quantity, freq.unit)
                    .isSameOrBefore(moment(), freq.unit);
                } else {
                  return true;
                }
              } else {
                return true;
              }
            } catch (e) {
              console.error(`checklists has error(s) in ${c._id}`, e.message);
            }
            return false;
          })
          .map((item, index) => {
            return {
              ...item,
              index: index + 1
            };
          });
      }
    }
  },
  computed: {
    ...mapState('checklistFill', ['loading', 'availableChecklists', 'storeSelected', 'userFilledChecklists']),
    ...mapState('cstSubmission', ['store']),
    ...mapState('darkMode', ['isDarkMode']),
    userSavedReports() {
      return this.userFilledChecklists;
    },
    checklists() {
      return this.availableChecklists;
    }
  },
  watch: {
    selectedStore(newValue) {
      this.list({ storeId: newValue });
      localStorage.setItem('storeId', newValue);
      this.selectStore({ storeId: newValue });
    },
    store(newValue) {
      if (newValue !== undefined) {
        this.stores = newValue.map(store => store.storeid);
        if (newValue.length === 1) {
          this.selectedStore = newValue[0].storeid;
        }
      }
    },
    availableChecklists(newValue) {
      this.todayToDo = this.getChecklistsToShow({
        selectedStore: this.selectedStore,
        userSavedReports: this.userSavedReports,
        checklists: newValue
      });
    }
  }
};
</script>
<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
