var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('h2',{staticClass:"page-title text-center"},[_vm._v(_vm._s(_vm.checklistName))])])],1),_c('b-row',[_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"box-shadow2 mx-2",attrs:{"variant":_vm.isDarkMode ? 'outline-light' : 'secondary',"size":"sm","disabled":false},on:{"click":function($event){return _vm.findCheckpointChange()}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'save']}}),_vm._v("Save ")],1),_c('b-button',{staticClass:"box-shadow2",attrs:{"size":"sm","disabled":_vm.isDisabled,"variant":_vm.isDarkMode ? 'outline-success' : 'success'},on:{"click":_vm.submitChecklist}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'save']}}),_vm._v("Submit ")],1),_c('b-button',{staticClass:"box-shadow2 ml-2",attrs:{"size":"sm","variant":_vm.isDarkMode ? 'outline-warning' : 'warning',"to":{ path: "/checklist-fill" }}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'long-arrow-alt-left']}}),_vm._v("Back to list ")],1)],1)],1),(_vm.showUpload)?_c('b-button',{staticClass:"box-shadow2 mr-2 mb-2",attrs:{"size":"sm","variant":_vm.isDarkMode ? 'outline-success' : 'success'},on:{"click":_vm.clickUpload}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'upload']}}),_vm._v(_vm._s(_vm.uploadText)+" ")],1):_vm._e(),(_vm.loading)?_c('b-overlay',{attrs:{"show":"","variant":_vm.isDarkMode ? 'outline-light' : 'outline-secondary'}},[_c('div',{class:{ 'table-row table-row-empty': !_vm.isDarkMode, 'table-row-dark table-row-empty': _vm.isDarkMode }},[_c('div',{class:{
          'table-column table-column-full text-center': !_vm.isDarkMode,
          'table-column-dark table-column-full text-center': _vm.isDarkMode
        }})])]):_vm._e(),_c('div',{staticClass:"box-shadow2 border-top"},[_c('div',{class:{
        'table-row table-row-header': !_vm.isDarkMode,
        'table-row-dark dark-div': _vm.isDarkMode
      }},_vm._l((_vm.columns),function(column,index){return _c('div',{key:column.id,class:Object.assign({}, column.class,
          {'table-column': !_vm.isDarkMode,
          'table-column-dark': _vm.isDarkMode,
          'table-column-first': index === 0,
          'table-column-last-odd': _vm.columns.length === index + 1 && (_vm.columns.length - 1) % 2 === 1}),style:(_vm.columnStyle(column))},[_vm._v(" "+_vm._s(column.headerText)+" ")])}),0),_vm._l((_vm.allCheckpoints),function(row){return _c('div',{key:row.id,class:{
        'table-row ': !_vm.isDarkMode,
        'table-row-dark': _vm.isDarkMode
      }},_vm._l((_vm.columns),function(column,index){return _c('div',{key:column.id,class:Object.assign({}, column.class,
          {'table-column ': !_vm.isDarkMode,
          'table-column-dark': _vm.isDarkMode,
          'table-column-first': index === 0,
          'table-column-last-odd': _vm.columns.length === index + 1 && (_vm.columns.length - 1) % 2 === 1}),style:(_vm.columnStyle(column))},[(column.slotKey)?_vm._t(column.slotKey,null,{"row":row}):_vm._e(),(column.htmlKey)?_c('div',{domProps:{"innerHTML":_vm._s(row[column.htmlKey])}}):_vm._e(),(column.urlKey)?_c('div',[_c('a',{attrs:{"href":row[column.urlKey],"target":"_blank"}},[_vm._v(_vm._s(row[column.urlKey]))])]):_vm._e(),(
            column.textKey &&
            column.textKey != 'checkpointType' &&
            column.textKey != 'picture' &&
            column.textKey != 'isImageMandatory'
          )?_c('span',{staticClass:"span-text"},[_vm._v(_vm._s(row[column.textKey]))]):_vm._e(),(column.type === 'image' && typeof row[column.textKey] != 'string' && row[column.textKey])?_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}]},[_c('div',[_vm._l((row[column.textKey]),function(image){return _c('div',{key:image,staticClass:"imageview"},[_c('img',{staticClass:"image",attrs:{"src":image}})])})],2)]):_vm._e(),(column.textKey === 'isImageMandatory')?_c('div',[_vm._v(" "+_vm._s(row[column.textKey] ? 'Yes' : 'No')+" ")]):_vm._e(),_c('link',{attrs:{"href":"//unpkg.com/viewerjs/dist/viewer.css","rel":"stylesheet"}}),(column.textKey === 'checkpointType' && row[column.textKey] === 'BOOLEAN')?_c('div',[_c('b-col',[_c('multiselect',{class:{
                'box-shadow3': !_vm.isDarkMode,
                darkMode: _vm.isDarkMode
              },attrs:{"id":"input-critical","placeholder":"Select","options":_vm.viewType},model:{value:(row.booleanAnswer),callback:function ($$v) {_vm.$set(row, "booleanAnswer", $$v)},expression:"row.booleanAnswer"}}),_c('b-form-textarea',{class:{ '': !_vm.isDarkMode, 'bg-dark text-light placeholder-light': _vm.isDarkMode },attrs:{"id":"input-2","rows":"3","required":"","placeholder":"Remarks"},model:{value:(row.remarks),callback:function ($$v) {_vm.$set(row, "remarks", $$v)},expression:"row.remarks"}})],1)],1):_vm._e(),(column.textKey === 'checkpointType' && row[column.textKey] === 'TARGET')?_c('div',[_c('b-form-input',{attrs:{"id":"input-2","required":"","placeholder":"Target"},model:{value:(row.target),callback:function ($$v) {_vm.$set(row, "target", $$v)},expression:"row.target"}}),_c('b-form-input',{attrs:{"id":"input-2","required":"","placeholder":"Actual"},model:{value:(row.actual),callback:function ($$v) {_vm.$set(row, "actual", $$v)},expression:"row.actual"}}),_c('b-form-textarea',{attrs:{"id":"input-2","rows":"3","required":"","placeholder":"Remarks"},model:{value:(row.remarks),callback:function ($$v) {_vm.$set(row, "remarks", $$v)},expression:"row.remarks"}})],1):_vm._e(),(column.type === 'functions')?_c('div',{staticClass:"text-center"},[_c('b-button-group',{attrs:{"vertical":""}},[(column.functions.edit)?_c('b-button',{class:{
                '': !_vm.isDarkMode,
                'text-white': _vm.isDarkMode
              },attrs:{"data-v-step":"5","size":"sm","variant":"outline-secondary"},on:{"~click":function($event){return _vm.clickSave(row)}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'edit']}}),_vm._v("Save ")],1):_vm._e()],1)],1):_vm._e()],2)}),0)}),(!_vm.loading && _vm.allCheckpoints.length === 0)?_c('div',{class:{ 'table-row table-row-empty': !_vm.isDarkMode, 'table-row-dark table-row-empty': _vm.isDarkMode }},[_c('div',{class:{
          'table-column table-column-full text-center': !_vm.isDarkMode,
          'table-column-dark table-column-full text-center': _vm.isDarkMode
        }},[_vm._v(_vm._s(_vm.emptyText))])]):_vm._e(),(_vm.loading)?_c('div',{class:{ 'table-row table-row-empty': !_vm.isDarkMode, 'table-row-dark table-row-empty': _vm.isDarkMode }},[_c('div',{class:{
          'table-column table-column-full text-center': !_vm.isDarkMode,
          'table-column-dark table-column-full text-center': _vm.isDarkMode
        }},[_c('span',{class:{
            'spinner spinner-black': !_vm.isDarkMode,
            'spinner spinner-white': _vm.isDarkMode
          }})])]):_vm._e()],2),_c('b-row',{staticClass:"mt-4"},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"box-shadow2 mx-2",attrs:{"variant":_vm.isDarkMode ? 'outline-light' : 'secondary',"size":"sm","disabled":false},on:{"click":function($event){return _vm.findCheckpointChange()}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'save']}}),_vm._v("Save ")],1),_c('b-button',{staticClass:"box-shadow2",attrs:{"size":"sm","disabled":_vm.isDisabled,"variant":_vm.isDarkMode ? 'outline-success' : 'success'},on:{"click":_vm.submitChecklist}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'save']}}),_vm._v("Submit ")],1),_c('b-button',{staticClass:"box-shadow2 ml-2",attrs:{"size":"sm","variant":_vm.isDarkMode ? 'outline-warning' : 'warning',"to":{ path: "/checklist-fill" }}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'long-arrow-alt-left']}}),_vm._v("Back to list ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }