<template>
  <div>
    <div>
      <h2 class="page-title text-center">Report</h2>
    </div>
    <div v-if="storeDetails.storeName !== undefined" class="mx-1 my-5 d-flex align-items-start justify-content-between">
      <b-col lg="3" sm="12" :class="{ custom_shadow: !isDarkMode, 'box-shadow2 dark-div rounded': isDarkMode }">
        <div class="d-flex my-3 justify-content-around align-items-center">
          <div> Store Status </div>
          <div>
            <b-button
              class="box-shadow2"
              :variant="
                isCriticalResponseSelected === true
                  ? 'danger'
                  : overallPercentage >= 80
                  ? 'success'
                  : overallPercentage < 80 && overallPercentage > 50
                  ? 'warning'
                  : 'danger'
              "
            >
              {{
                isCriticalResponseSelected === true
                  ? 'Red'
                  : overallPercentage >= 80
                  ? 'Green'
                  : overallPercentage < 80 && overallPercentage > 50
                  ? 'Yellow'
                  : 'Red'
              }}
            </b-button>
          </div>
        </div>
        <div class="table-responsive-md">
          <table
            :class="{
              'table table-hover custom-table': !isDarkMode,
              'table text-light custom-table': isDarkMode
            }"
          >
            <tbody>
              <tr>
                <td>Store Name</td>
                <td>{{ storeDetails.storeName !== undefined ? storeDetails.storeName : '' }}</td>
              </tr>
              <tr>
                <td>Store Manager</td>
                <td>{{ storeDetails.storeManagerName !== undefined ? storeDetails.storeManagerName : '' }}</td>
              </tr>
              <tr>
                <td>Audit Date</td>
                <td>{{ auditDetails.auditDate !== undefined ? auditDetails.auditDate : '' }}</td>
              </tr>
              <tr>
                <td>Auditor</td>
                <td>{{ auditDetails.auditor !== undefined ? auditDetails.auditor : '' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
      <b-col
        lg="3"
        sm="12"
        :class="{ 'p-2 mx-2 custom_shadow': !isDarkMode, 'box-shadow2 dark-div rounded': isDarkMode }"
      >
        <div class="d-flex my-3 justify-content-around align-items-center">
          <div class="h6"> Region </div>
          <div class="h6">{{ storeDetails.region }}</div>
        </div>
        <div class="table-responsive-md">
          <table
            :class="{
              'table table-bordered table-hover': !isDarkMode,
              'table table-bordered text-light': isDarkMode
            }"
          >
            <tbody>
              <tr class="">
                <td colspan="2" class="text-center">Grading</td>
              </tr>
              <tr>
                <td>Green</td>
                <td>>= 80%</td>
              </tr>
              <tr>
                <td>Yellow</td>
                <td>50% - 79%</td>
              </tr>
              <tr>
                <td>Red</td>
                <td> -> 50%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
      <b-col lg="5" sm="12" :class="{ custom_shadow: !isDarkMode, 'box-shadow2 dark-div rounded': isDarkMode }">
        <div class="d-flex my-3 justify-content-around align-items-center">
          <div class="h6"> Score </div>
          <div class="h6">
            <b-button
              class="btn-lg box-shadow2"
              :variant="
                isCriticalResponseSelected === true
                  ? 'danger'
                  : overallPercentage >= 80
                  ? 'success'
                  : overallPercentage < 80 && overallPercentage > 50
                  ? 'warning'
                  : 'danger'
              "
            >
              {{ overallPercentage }} %</b-button
            >
          </div>
        </div>
        <div class="table-responsive-md table-wrap">
          <table
            :class="{
              'table table-bordered table-hover': !isDarkMode,
              'table table-bordered text-light': isDarkMode
            }"
          >
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Achievable</th>
                <th scope="col">Achieved</th>
                <th scope="col">Percent</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, key) in reportScoreCard" :key="key">
                <td>{{ key }}</td>
                <td>{{ item.totalWeightAge }}</td>
                <td>{{ item.totalScore }}</td>
                <td>{{
                  item.percentage === 100 || item.percentage === 0 ? item.percentage : item.percentage.toFixed(2)
                }}</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th scope="col">Total</th>
                <th scope="col">{{ totalWeightAge }}</th>
                <th scope="col">{{ totalScore }}</th>
                <th scope="col">{{ overallPercentage }}</th>
              </tr>
            </thead>
          </table>
        </div>
      </b-col>
    </div>
    <table-box
      :checklistName="checklistName"
      :columns="columns"
      :rows="finalCheckpoints ? finalCheckpoints : []"
      :categoriesAndDepartments="categoriesAndDepartments"
      :format="this.$route.query.type"
      :ID="this.$route.params.id"
      :checklistStores="checklistStores"
      :roleOptions="roleOptions"
      emptyText="no checkpoint found"
      uploadText="Upload Checkpoints"
      :onlySavedReport="onlySavedReport"
    >
    </table-box>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import _ from 'lodash';
import Vue from 'vue';
import csv from 'csvtojson';
import TableBox from '@/components/TableBox.vue';
import moment from 'moment';
// import ChecklistV2FillBox from '../../components/ChecklistV2/ChecklistFillPart/ChecklistV2FillBox.vue';

export default {
  name: 'Checklist_V2_Fill',
  components: {
    TableBox
    // ChecklistV2FillBox
  },

  async mounted() {
    this.id = this.$route.params.id;
    this.getUserFilledCheckpoints({
      reportId: this.$route.params.id
    });
    this.getOneReportData({ id: this.$route.params.id });
  },
  data() {
    return {
      storeDetails: {},
      isCriticalResponseSelected: false,
      reportScoreCard: {},
      formType: '',
      roleOptions: [
        { value: null, text: 'Select Role' },
        { value: 'QUALITY_AUDIT_HEAD', text: 'Quality Head' },
        { value: 'QUALITY_AUDIT_MANAGER', text: 'Quality Manager' },
        { value: 'QUALITY_AUDIT_EXECUTIVE', text: 'Quality Executive' }
      ],
      id: null,
      checklistStores: [],
      checklistName: '',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'serialNumber',
          width: '3%'
        },
        {
          type: 'row_num',
          headerText: 'Category',
          class: { ' text-center': true },
          textKey: 'category',
          width: '5%'
        },
        {
          type: 'row_num',
          headerText: 'Question',
          class: { ' text-center': true },
          textKey: 'questionText',
          width: '19%'
        },
        {
          type: 'row_num',
          headerText: 'Key Point',
          class: { ' text-center': true },
          textKey: 'keyPoint',
          width: '4%'
        },
        {
          type: 'image',
          headerText: 'Department/ Category',
          class: { ' text-center': true },
          textKey: 'departmentOrCategory',
          width: '10%'
        },
        {
          type: 'row_num',
          headerText: 'Response',
          class: { ' text-center': true },
          textKey: 'response',
          width: '10%'
        },
        {
          type: 'row_num',
          headerText: 'Auditor observation',
          class: { ' text-center': true },
          textKey: 'auditObservation',
          width: '10%'
        },
        {
          type: 'row_num',
          headerText: 'Auditor Recommendation',
          class: { ' text-center': true },
          textKey: 'auditorsRecommendation',
          width: '10%'
        },
        {
          type: 'row_num',
          headerText: 'Auditee Comment',
          class: { ' text-center': true },
          textKey: 'auditeeComment',
          width: '12%'
        },
        {
          type: 'row_num',
          headerText: 'FPR Designation',
          class: { ' text-center': true },
          textKey: 'fprAndDesignation',
          width: '10%'
        },

        {
          type: 'row_num',
          headerText: 'Closure Date',
          class: { ' text-center': true },
          textKey: 'closureDate',
          width: '7%'
        }
      ],
      rows: [],
      finalCheckpoints: [],
      onlySavedReport: false
    };
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Add New Checklist' : 'Update Checklist';
    },
    ...mapState('checklist', ['loading', 'checklist']),
    ...mapState('checklistFill', ['loading']),
    ...mapState('checklistV2Fill', [
      'loading',
      'fillingCheckpoints',
      'userFilledCheckpoints',
      'checkpointResponse',
      'categoriesAndDepartments',
      'singleReportData'
    ]),
    ...mapState('darkMode', ['isDarkMode']),
    totalScore() {
      return Object.values(this.reportScoreCard).reduce((acc, categoryData) => acc + categoryData.totalScore, 0);
    },
    totalWeightAge() {
      return Object.values(this.reportScoreCard).reduce((acc, categoryData) => acc + categoryData.totalWeightAge, 0);
    },
    overallPercentage() {
      return this.totalWeightAge > 0 ? ((this.totalScore / this.totalWeightAge) * 100).toFixed(2) : 0;
    }
  },
  methods: {
    ...mapActions('checklistFill', ['getCheckpoints']),
    ...mapActions('checklistV2Fill', ['getOne', 'postOne', 'patchOne', 'getUserFilledCheckpoints', 'getOneReportData']),
    convertIntoUnderScore(str) {
      return str.split(',').join('\\-');
    },
    convertBackToComma(str) {
      return str.split('\\-').join(',');
    }
  },
  watch: {
    userFilledCheckpoints(newValue) {
      if (newValue.length > 0) {
        this.finalCheckpoints = newValue
          .map((i, index) => ({
            ...i,
            rowNum: index + 1,
            closureDate: i.closureDate !== null ? moment(i.closureDate).format('DD-MM-YYYY') : '',
            keyPoint: i.keyPoint !== true ? null : '✔'
          }))
          .sort((a, b) => a.serialNumber - b.serialNumber);
        const criticalValidation = newValue.find(item => item.category === 'CRITICAL' && item.response === 'CRITICAL');
        if (criticalValidation !== undefined) {
          this.isCriticalResponseSelected = true;
        } else {
          this.isCriticalResponseSelected = false;
        }
        this.reportScoreCard = newValue.reduce((acc, entry) => {
          const { score, weightAge, category } = entry;
          acc[category] = acc[category] || { totalScore: 0, totalWeightAge: 0 };
          acc[category].totalScore += score || 0;
          acc[category].totalWeightAge += weightAge || 0;
          acc[category].percentage =
            acc[category].totalScore / acc[category].totalWeightAge
              ? (acc[category].totalScore / acc[category].totalWeightAge) * 100
              : 0;

          return acc;
        }, {});
      }
    },
    checklist(value) {
      const stores = value.storeids;
      this.checklistStores = stores.length > 0 ? stores : [];
    },
    singleReportData(newValue) {
      if (Object.keys(newValue).length > 0) {
        this.storeDetails = newValue?.store;
        this.auditDetails = {
          auditor: newValue.submittedByName,
          auditDate: moment(newValue.auditDate).format('DD-MMMM-YYYY')
        };
        this.numberOfExpiryUnit = newValue.expireUnits;
        this.numberOfExpireSKU = newValue.expireSKU;
        this.emailArray = newValue?.sendEmailTo?.join(',');
        this.roleSendTo = newValue.sendReportTo;
      }
    },
    reportScoreCard(newValue) {
      if (newValue.length > 0) {
        const abc = newValue.reduce((acc, curr) => {
          acc.totalAchievable += curr.totalScore;
          acc.totalAchieved += curr?.totalWeightAge ? curr.totalWeightAge : 0;
          acc.totalPercentage += curr.percentage;
        }, {});
      }
    }
  }
};
</script>
<style>
.custom_shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 12px;
}
</style>
