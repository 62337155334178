<template>
  <div>
    <h1 class="page-title text-center">{{ formatType }}</h1>
    <table-box
      :columns="columnsForDraft"
      :rows="draftedChecklists"
      :baseUrl="baseUrl"
      :loading="loading"
      emptyText="No checklist found"
      :showAdd="false"
      @open="onView"
    >
    </table-box>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import TableBox from '@/components/TableBox.vue';
import moment from 'moment';

export default {
  name: 'DraftChecklists',
  components: { TableBox },
  data() {
    return {
      columnsForDraft: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'rowNum',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'CheckList Name',
          textKey: 'checkListName',
          class: { 'text-center': true },
          width: '30%'
        },
        {
          type: 'string',
          headerText: 'Completed',
          textKey: 'completed',
          class: { 'text-center': true },
          width: '13%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          textKey: 'storeid',
          class: { 'text-center': true },
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Created At',
          class: { 'text-center': true },
          textKey: 'updatedAt',
          width: '15%'
        },
        {
          type: 'string',
          headerText: 'Overall Score',
          class: { 'text-center': true },
          textKey: 'overallScore',
          width: '12%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '15%',
          functions: {
            open: true
          }
        }
      ],
      formatType: '',
      draftedChecklists: [],
      CHECKLIST_FREQUENCY: Object.freeze({
        'ONE-TIME': {
          unit: 'years',
          quantity: 100
        },
        DAILY: {
          unit: 'days',
          quantity: 1
        },
        WEEKLY: {
          unit: 'days',
          quantity: 7
        },
        BIWEEKLY: {
          unit: 'days',
          quantity: 14
        },
        MONTHLY: {
          unit: 'months',
          quantity: 1
        },
        'HALF-YEARLY': {
          unit: 'months',
          quantity: 6
        },
        YEARLY: {
          unit: 'years',
          quantity: 1
        }
      })
    };
  },
  mounted() {
    this.formatType = this.$route.query.format;
    if (this.storeSelected !== '' || this.storeSelected || undefined) {
      this.list({ storeId: this.storeSelected });
    }
    this.userReports();
  },
  methods: {
    ...mapActions('checklistFill', ['list', 'userReports', 'selectStore']),
    ...mapActions('cstSubmission', ['getStoreByUser']),
    onView({ row }) {
      if (this.$route.query.format === 'Submitted') {
        router.push(`/report/${row.id}`);
      } else {
        router.push({
          path: `/checklist-fill/${row.id}`,
          query: { type: 'report', checklistID: row.checklistID }
        });
      }
    },
    // onView({ row }) {
    //   if (this.$route.query.format === 'Submitted') {
    //     router.push(`/report/${row.id}`);
    //   } else {
    //     router.push({
    //       path: `/checklist-fill-version-2/${row.id}`,
    //       query: { type: 'report', checklistID: row.checklistID }
    //     });
    //   }
    // },
    getReportsToShow({ selectedStore, userSavedReports, checklists }) {
      return userSavedReports
        .filter(u => u.completed === 'False' && u.storeid === selectedStore)
        .map((u, index) => {
          try {
            const foundChecklist = checklists.find(c => u?.checklistID === c.id);
            if (foundChecklist) {
              const freq = this.CHECKLIST_FREQUENCY[foundChecklist.frequency];
              let hasExpired = false;
              if (freq) {
                hasExpired = moment(u.createdAt).add(freq.quantity, freq.unit).isSameOrBefore(moment(), 'day');
              }
              return {
                ...foundChecklist,
                rowNum: index + 1,
                id: u.id,
                createdAt: u.createdAt,
                hasExpired,
                checkListName: u.checkListName,
                checklistID: u.checklistID,
                completed: `${u.completed} - ${u.overallProgress === -1 ? 'NA' : u.overallProgress * 100} ${'%'}`,
                storeid: u.storeid,
                overallScore: u.overallScore,
                updatedAt: moment(u.updatedAt).format('YYYY-MM-DD hh:mm A')
              };
            }
          } catch (e) {
            console.error(`userSavedReports has error(s) in ${u.id}`, e.message);
          }
          return null;
        })
        .filter(u => u !== null);
    }
  },
  computed: {
    ...mapState('checklistFill', [
      'loading',
      'baseUrl',
      'availableChecklists',
      'userFilledChecklists',
      'storeSelected'
    ]),
    ...mapState('cstSubmission', ['store']),
    report() {
      if (this.formatType === 'Submitted') {
        return this.userFilledChecklists
          .filter(i => i.completed === 'True')
          .map(i => ({
            ...i,
            completed: `${i.completed} - ${i.overallProgress === -1 ? 'NA' : i.overallProgress * 100} ${'%'}`
          }));
      } else {
        return this.userFilledChecklists
          .filter(i => i.completed === 'False' && i.storeid === this.storeSelected)
          .map(i => ({
            ...i,
            completed: `${i.completed} - ${i.overallProgress === -1 ? 'NA' : i.overallProgress * 100} ${'%'}`
          }));
      }
    }
  },
  watch: {
    availableChecklists(newValue) {
      if (this.$route.query.format === 'Submitted') {
        this.draftedChecklists = this.userFilledChecklists
          .filter(i => i.completed === 'True')
          .map((i, z) => ({
            ...i,
            completed: `${i.completed} - ${i.overallProgress === -1 ? 'NA' : i.overallProgress * 100} ${'%'}`,
            updatedAt: moment(i.updatedAt).format('YYYY-MM-DD hh:mm a')
          }));
      } else {
        const tempDraftedChecklists = this.getReportsToShow({
          selectedStore: this.storeSelected,
          userSavedReports: this.userFilledChecklists,
          checklists: newValue
        });
        this.draftedChecklists = tempDraftedChecklists.map((i, z) => ({
          ...i,
          updatedAt: moment(i.updatedAt).format('YYYY-MM-DD hh:mm a')
        }));
      }
    }
  }
};
</script>
