<template>
  <div>
    <h1 class="page-title text-center">Todo - Food Safety Checklists </h1>

    <b-form-group id="group-products" label-for="input-products">
      <template v-slot:label>
        Select Store ID
        <span class="text-danger">*</span>
      </template>
      <!-- <b-form-select v-model="selectedStore" :options="stores"> </b-form-select> -->
      <multiselect
        :class="{ 'box-shadow3': !isDarkMode, darkMode: isDarkMode }"
        placeholder="Select Store Id"
        v-model="selectedStore"
        :options="stores.map(item => item.text)"
      ></multiselect>
    </b-form-group>

    <div v-if="loading" class="text-center">
      <span class="spinner spinner-black"></span>
      <span><strong> Processing Please Wait ....</strong> </span>
    </div>
    <checklist-box
      :columns="columns"
      :showAdd="true"
      :showAddReview="false"
      :rows="todayToDo"
      :loading="loading"
      addText="My Reports"
      addTextReview="Review checklists"
      downloadText="Submitted checklists"
      emptyText="Please select the store first"
      @fill-checklist="onFillChecklist"
      @add="onAdd"
      @addReview="onAddReview"
    ></checklist-box>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import moment from 'moment';
import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import ChecklistBox from '@/components/ChecklistBox.vue';
export default {
  name: 'FoodSafetyFillChecklist',
  components: { ChecklistBox, Multiselect },
  mounted() {
    this.getStoreByUser();
    const localStore = localStorage.getItem('storeId') || '';
    this.selectedStore = localStore;
    this.selectStore({ storeId: localStore });
    this.getUserFilledReport({ router });
  },
  data() {
    return {
      navigateChecklistId: '',
      selectedStore: '',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'index',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Checklist Name',
          class: { 'text-center': true },
          textKey: 'name',
          width: '36%'
        },
        {
          type: 'string',
          headerText: 'Frequency',
          class: { 'text-center': true },
          textKey: 'frequency',
          width: '15%'
        },
        {
          type: 'string',
          headerText: 'Active Till',
          class: { 'text-center': true },
          textKey: 'activeTillTime',
          width: '20%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '24%',
          functions: {
            fill: true
          }
        }
      ],
      canFillChecklists: [],
      stores: [],
      todayToDo: [],
      CHECKLIST_FREQUENCY: Object.freeze({
        'ONE-TIME': {
          unit: 'years',
          quantity: 100
        },
        DAILY: {
          unit: 'days',
          quantity: 1
        },
        WEEKLY: {
          unit: 'days',
          quantity: 7
        },
        BIWEEKLY: {
          unit: 'days',
          quantity: 14
        },
        MONTHLY: {
          unit: 'months',
          quantity: 1
        },
        'HALF-YEARLY': {
          unit: 'months',
          quantity: 6
        },
        YEARLY: {
          unit: 'years',
          quantity: 1
        }
      })
    };
  },
  methods: {
    ...mapActions('checklistV2Fill', ['list', 'selectStore', 'getUserFilledReport', 'checkSubmittedChecklistV2']),
    ...mapActions('cstSubmission', ['getStoreByUser']),
    ...mapActions('report', ['listReport', 'download']),
    async onFillChecklist({ row }) {
      this.checkSubmittedChecklistV2({
        query: {
          storeId: this.selectedStore.slice(-4),
          checklist: row?.id
        }
      });
      this.navigateChecklistId = row?.id;
    },
    onView({ row }) {
      router.push(`/checklistV2_Fill/${row.id}`);
    },
    onAdd() {
      router.push({ path: `/draft-checklistsV2`, query: { format: 'Draft' } });
    },
    onAddReview() {
      router.push({ path: `/draft-checklistsV2`, query: { format: 'Review' } });
    },
    getLatestReport(reports) {
      const latestReport = reports.reduce((acc, curr) => {
        if (new Date(curr.createdAt).getTime() > new Date(acc.createdAt).getTime()) {
          return curr;
        }
        return acc;
      }, reports[0]);

      return latestReport;
    },
    getChecklistsToShow({ selectedStore, userSavedReports, checklists }) {
      if (userSavedReports.length === 0) {
        return checklists;
      } else {
        return checklists
          .filter(c => {
            try {
              const matchingReports = userSavedReports.filter(
                u => u?.checklist?._id === c?.id && u?.storeid.toString() === selectedStore.toString()
              );
              const latestReport = this.getLatestReport(matchingReports);
              if (latestReport) {
                const freq = this.CHECKLIST_FREQUENCY[c.frequency];
                if (freq) {
                  return moment(latestReport.createdAt)
                    .add(freq.quantity, freq.unit)
                    .isSameOrBefore(moment(), freq.unit);
                } else {
                  return true;
                }
              } else {
                return true;
              }
            } catch (e) {
              console.error(`checklists has error(s) in ${c.id}`, e.message);
            }
            return false;
          })
          .map((item, index) => {
            return {
              ...item,
              index: index + 1
            };
          });
      }
    }
  },
  computed: {
    ...mapState('checklistV2Fill', ['loading', 'checklistsV2', 'userFilledReport', 'lastMonthFilledChecklist']),
    ...mapState('cstSubmission', ['store']),
    ...mapState('darkMode', ['isDarkMode']),
    userSavedReports() {
      return this.userFilledReport;
    },
    checklists() {
      return this.checklistsV2;
    }
  },
  watch: {
    lastMonthFilledChecklist(newValue) {
      if (Object.keys(newValue).length > 0) {
        if (newValue.status === false) {
          Vue.swal({
            title: 'Already Filled',
            text: `Checklist has been filled, you can not fill the same checklist twice`,
            icon: 'error',
            background: this.isDarkMode ? '#464d54' : '#ffffff',
            customClass: {
              title: this.isDarkMode ? 'text-light' : 'text-dark',
              content: this.isDarkMode ? 'text-light' : 'text-dark'
            }
          });
          return null;
        } else {
          router.push(`/checklistV2_Fill/${this.navigateChecklistId}`);
        }
      }
    },
    selectedStore(newValue) {
      const store = String(newValue)?.slice(-4);
      this.list({ storeId: store });
      // this.list({
      //   query: {
      //     search: newValue,
      //     searchKey: 'Store Id'
      //   }
      // });
      localStorage.setItem('storeId', store);
      this.selectStore({ storeId: store });
    },
    store(newValue) {
      if (newValue !== undefined) {
        this.stores = newValue.map(store => ({ text: `${store.storeName} - ${store.storeid}` }));
        if (newValue.length === 1) {
          this.selectedStore = `${newValue[0].storeName} - ${newValue[0].storeid}`;
        }
      }
    },
    checklistsV2(newValue) {
      this.todayToDo = this.getChecklistsToShow({
        selectedStore: this.selectedStore,
        userSavedReports: this.userSavedReports,
        checklists: newValue
      });
    }
  }
};
</script>
<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
