<template>
  <div class="page-class page-setting-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <checklist-report-box-v2
      :columns="columnToShow"
      :rows="reports"
      baseUrl="foodSafety_Reports"
      :pagination="pagination"
      :showFilter="true && !isSm"
      :showAdd="true && !isSm"
      :showUpload="true && !isSm"
      :showDownloadDashboard="true"
      :showDownloadReport="true"
      :showDownloadKeyPoint="true"
      :searchItems="searchItems"
      :loading="loading"
      :sortfilter="true"
      downloadKeyPointText="Key Points Report"
      downloadReportText="Summary Report"
      emptyText="No Report found."
      addText="Add New Audit"
      downloadDashboardText="Dashboard Report"
      uploadText="Review Checklists"
      deleteText="Delete Report"
      :showToolbar="true"
      :filterOptions="filterOptions"
      @open="onView"
      @add="handleNew"
      :showPagination="true"
      @show-upload="onReview"
      @download-dashboard="onDownloadDashboard"
      @download-active-report="onDownloadReport"
      @download-Key-Point="onDownloadKeyPoint"
      @download-audit="onDownloadSingleReport"
      @delete="onDeleteReport"
    ></checklist-report-box-v2>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import ChecklistReportBoxV2 from '../../components/ChecklistV2/ChecklistReportBoxV2.vue';
import router from '@/router';
import _ from 'lodash';

export default {
  name: 'ReportList',
  components: {
    ChecklistReportBoxV2
  },
  metaInfo() {
    return {
      title: 'Food Safety Reports',
      meta: [
        {
          name: 'description',
          content: 'View Reports'
        }
      ]
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.listReleasedReports({
        query: {
          ...this.$route.query,
          startDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.listReleasedReports({ query: this.$route.query });
    }
  },

  beforeRouteUpdate(to, from, next) {
    this.listReleasedReports({ query: to.query });
    next();
  },
  data() {
    return {
      dashboardReportData: [],
      title: 'Food Safety Reports',
      stores: [{ text: 'All Stores', value: 'all' }],
      storeSelected: 'all',
      searchItems: ['CheckList Name', 'Store Id', 'Store Name'],
      sortFilterV2: false,
      sortFilterRelease: false,
      sortFilterClose: false,
      sortedData: [],
      CloseData: [],
      ReleaseData: [],
      CreatedData: [],
      StatusData: [],
      sortFilterStatus: false,
      sortFilterScore: false,
      sortFilterCreatedBy: false,
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'DRAFT', text: 'DRAFT' },
        { value: 'REVIEW', text: 'REVIEW' },
        { value: 'RELEASED', text: 'RELEASED' },
        { value: 'CLOSED', text: 'CLOSED' }
      ]
    };
  },
  computed: {
    ...mapState('checklistV2Fill', [
      'loading',
      'baseUrl',
      'releasedReportsV2',
      'pagination',
      'downloadReportsV2',
      'keyPointReports',
      'userFilledCheckpoints',
      'downloadDashboard'
    ]),
    ...mapState('store', ['storeList']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('auth', ['user', 'foodSafetyTeam']),
    ...mapGetters('auth', ['isFoodSafetyAdmin', 'isSm']),
    columnToShow() {
      if (this.isFoodSafetyAdmin) {
        return [
          {
            type: 'row_num',
            headerText: '#',
            class: { 'text-center': true },
            textKey: 'rowNum',
            width: '3%'
          },

          {
            type: 'string',
            headerText: 'CheckList Name',
            textKey: 'checklistName',
            class: { 'text-center': true },
            width: '10%'
          },
          {
            type: 'string',
            headerText: 'Store',
            textKey: 'storeName',
            class: { 'text-center': true },
            width: '13%'
          },
          {
            type: 'string',
            headerText: 'Store Id',
            textKey: 'storeid',
            class: { 'text-center': true },
            width: '5%'
          },

          {
            type: 'string',
            headerText: 'Status',
            textKey: 'completed',
            class: { 'text-center': true },
            width: '13%'
          },

          {
            type: 'string',
            headerText: 'Audit Date',
            class: { 'text-center': true },
            textKey: 'auditDate',
            width: '9%'
          },
          {
            type: 'string',
            headerText: 'Release Date',
            class: { 'text-center': true },
            textKey: 'releaseDate',
            width: '10%'
          },
          {
            type: 'string',
            headerText: 'Close Date',
            class: { 'text-center': true },
            textKey: 'reportClosureDate',
            width: '9%'
          },
          {
            type: 'string',
            headerText: 'Created By',
            textKey: 'submittedByName',
            class: { 'text-center': true },
            width: '8%'
          },
          {
            type: 'string',
            headerText: 'Status',
            textKey: 'reportStatusColor',
            class: { 'text-center': true },
            width: '6%'
          },
          {
            type: 'string',
            headerText: 'Score (%)',
            class: { 'text-center': true },
            textKey: 'overallScore',
            width: '4%'
          },
          {
            type: 'functions',
            headerText: 'Functions',
            class: { 'text-center': true },
            width: '10%',
            functions: {
              open: true,
              audit: true,
              delete: true
            }
          }
        ];
      }
      return [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'rowNum',
          width: '3%'
        },

        {
          type: 'string',
          headerText: 'CheckList Name',
          textKey: 'checklistName',
          class: { 'text-center': true },
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Store',
          textKey: 'storeName',
          class: { 'text-center': true },
          width: '13%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          textKey: 'storeid',
          class: { 'text-center': true },
          width: '5%'
        },

        {
          type: 'string',
          headerText: 'Status',
          textKey: 'completed',
          class: { 'text-center': true },
          width: '13%'
        },

        {
          type: 'string',
          headerText: 'Audit Date',
          class: { 'text-center': true },
          textKey: 'auditDate',
          width: '9%'
        },

        {
          type: 'string',
          headerText: 'Release Date',
          class: { 'text-center': true },
          textKey: 'releaseDate',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Close Date',
          class: { 'text-center': true },
          textKey: 'reportClosureDate',
          width: '9%'
        },
        {
          type: 'string',
          headerText: 'Created By',
          textKey: 'submittedByName',
          class: { 'text-center': true },
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Status',
          textKey: 'reportStatusColor',
          class: { 'text-center': true },
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Score (%)',
          class: { 'text-center': true },
          textKey: 'overallScore',
          width: '4%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '10%',
          functions: {
            open: true,
            audit: true,
            delete: false
          }
        }
      ];
    },
    reports() {
      return this.releasedReportsV2.map(i => ({
        ...i,
        auditDate: i.auditDate ? moment(i.auditDate).format('DD-MM-YYYY') : '',
        reportClosureDate: i.reportClosureDate ? moment(i.reportClosureDate).format('DD-MM-YYYY') : '',
        storeName: i.store?.storeName,
        overallScore: (Number(i.overallScore) * 100).toFixed(2),

        releaseDate: i.releaseDate ? moment(i.releaseDate).format('DD-MM-YYYY') : '',
        completed:
          i.reportClosureDate && i.reportClosureDate !== null
            ? 'Report Closed'
            : i.completed === true
            ? 'Report Released'
            : i.sendReportTo === null
            ? `Draft`
            : `Report Send to ${i.sendReportTo} for Review `
      }));
    }
  },

  methods: {
    ...mapActions('checklistV2Fill', [
      'listReleasedReports',
      'downloadDashboardReport',
      'downloadReleasedReports',
      'downloadKeyPointReports',
      'getUserFilledCheckpoints',
      'deleteFoodSafetyReport'
    ]),

    async onDeleteReport({ row }) {
      await Vue.swal({
        title: 'Delete file',
        text: 'Are you sure, you would not able to revert this',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showCancelButton: true,
        cancelButtonText: 'Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDenied) {
          return null;
        }
        if (result.value) {
          this.deleteFoodSafetyReport({ id: row._id });
        }
      });
    },
    onDownloadSingleReport({ row }) {
      this.getUserFilledCheckpoints({
        reportId: row._id
      });
    },
    onDownloadReport() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.downloadReleasedReports({
          query: { ...this.$route.query, page_size: 99999, page: 1 }
        });
      } else {
        this.downloadReleasedReports({
          query: {
            ...this.$route.query,
            page_size: 99999,
            page: 1,
            startdate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
          }
        });
      }
    },
    onDownloadDashboard() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.downloadDashboardReport({
          query: {
            ...this.$route.query,
            startDate: this.$route.query.startdate,
            endDate: this.$route.query.enddate,
            page_size: 99999
          }
        });
      } else {
        this.downloadDashboardReport({
          query: {
            ...this.$route.query,
            page_size: 99999
          }
        });
      }
    },
    onDownloadKeyPoint() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.downloadKeyPointReports({
          query: {
            ...this.$route.query,
            startDate: this.$route.query.startdate,
            endDate: this.$route.query.enddate
          }
        });
      } else {
        this.downloadKeyPointReports({
          query: {
            ...this.$route.query,
            startdate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
          }
        });
      }
    },
    onReview() {
      router.push({ path: `/draft-checklistsV2`, query: { format: 'Review' } });
    },
    handleNew() {
      router.push('/checklistV2_Fill');
    },
    onView({ row }) {
      if (row.reportClosureDate === '' && this.isSm) {
        router.push({
          path: `/checklistV2_Fill/${row._id}`,
          query: { type: 'report', checklistID: row.checklist, from: 'released' }
        });
      } else if (row.releaseDate === '' && this.user?.roles?.map(i => i.name).includes(row.sendReportTo)) {
        router.push({
          path: `/checklistV2_Fill/${row._id}`,
          query: { type: 'report', checklistID: row.checklist }
        });
      } else if (row.completed.includes('Draft') && row.submittedByName === this.user?.name) {
        router.push({
          path: `/checklistV2_Fill/${row._id}`,
          query: { type: 'report', checklistID: row.checklist }
        });
      } else {
        router.push({
          path: `/reportFormV2/${row._id}`
        });
      }
    }
  },
  watch: {
    downloadDashboard(newValue) {
      if (newValue.length > 0) {
        let csv = '';
        csv += `Sr No,Checklist Name,Store Name,Store Id,Status,Audit Date,Release Date,Close Date,Created By,Status,Score% `;
        csv += '\n';

        const colorMap = {
          '#52bf90': 'Green',
          '#ee4035': 'Red',
          '#fdf498': 'Yellow'
        };

        newValue.forEach(row => {
          let completed =
            row.reportClosureDate && row.reportClosureDate !== null
              ? 'Report Closed'
              : row.completed === true
              ? 'Report Released'
              : row.sendReportTo === null
              ? `Draft`
              : `Report Send to ${row.sendReportTo} for Review `;
          csv += `"${row.rowNum}",`;
          csv += `"${row.checklistName}",`;
          csv += `"${row.store.storeName}",`;
          csv += `"${row.store.storeid}",`;
          csv += `"${completed}",`;
          csv += row.auditDate ? `"${moment(row.auditDate).format('DD-MM-YYYY')}",` : ',';
          csv += row.releaseDate ? `"${moment(row.releaseDate).format('DD-MM-YYYY')}",` : `  ,`;
          csv += row.reportClosureDate ? `"${moment(row.reportClosureDate).format('DD-MM-YYYY')}",` : ',';
          csv += `"${row.submittedByName}",`;
          const statusColor = colorMap[row.reportStatusColor] || row.reportStatusColor;
          csv += `"${statusColor}",`;
          csv += `"${(row.overallScore * 100).toFixed(2)}%",`;
          csv += '\n';
        });

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Dashboard Report.csv';
        anchor.click();
      }
    },
    userFilledCheckpoints(newValue) {
      if (newValue.length > 0) {
        let csv = '';
        csv += `Sr No,Category, Question, Is Key Point, Department/Category ,Response, Audit Observation, Auditor Recommendation,Auditee Comment,  FPR Designation, Closure Date,\n`;
        newValue.forEach((item, index) => {
          csv += `${item.serialNumber},${item.category},${item.questionText.replaceAll(',', '')},${item.keyPoint},${
            item.departmentOrCategory
          },${item.response},${item.auditObservation.replaceAll(',', '')},${item.auditorsRecommendation.replaceAll(
            ',',
            ''
          )},${item.auditeeComment.replaceAll(',', '')},${item.fprAndDesignation},${
            item.closureDate ? moment(item.closureDate).format('DD-MM-YYYY') : ''
          }\n`;
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Question-Wise Report.csv';
        anchor.click();
      }
    },
    downloadReportsV2(newValue) {
      if (newValue.length > 0) {
        let csv = ',,,,,,';
        const monthNames = moment.months();
        const startMoment = moment(this.$route.query.startdate);
        const endMoment = this.$route.query.enddate ? moment(this.$route.query.enddate) : moment();
        const startYear = startMoment.year();
        const endYear = endMoment.year();

        let yearWiseMonths = [];
        let totalMonths = (endYear - startYear) * 12 + (endMoment.month() - startMoment.month()) + 1;

        for (let i = 0; i < totalMonths; i++) {
          let currentYear = startYear + Math.floor((startMoment.month() + i) / 12);
          let currentMonth = (startMoment.month() + i) % 12;
          yearWiseMonths.push(`${monthNames[currentMonth].toString().slice(0, 3)}'${currentYear.toString().slice(-2)}`);
        }
        yearWiseMonths.forEach(monthName => {
          csv += `,${monthName},,,`;
        });
        csv += '\n';
        csv += `Sr No,Checklist Name,Store Id,Store Name,Region,ASM Name`;

        yearWiseMonths.forEach(month => {
          csv += `,Score,Status,Expire Units,Expire SKU`;
        });

        csv += '\n';

        let storeRows = [];
        let dcrcRows = [];
        let dcRows = [];

        newValue.forEach(row => {
          if (row.checklistName.includes('STORE')) {
            storeRows.push(row);
          } else if (row.checklistName.includes('DCRC')) {
            dcrcRows.push(row);
          } else {
            dcRows.push(row);
          }
        });

        function getHeading(checklistName) {
          if (checklistName.includes('STORE')) {
            return ',,,STORE';
          } else if (checklistName.includes('DCRC')) {
            csv += '\n';
            return ',,,DCRC';
          } else {
            csv += '\n';
            return ',,,DC';
          }
        }

        const processRows = rows => {
          let dataMap = new Map();
          rows.forEach(row => {
            const key = `${row.checklistName}-${row.storeId}`;
            if (!dataMap.has(key)) {
              dataMap.set(key, {
                checklistName: row.checklistName,
                storeId: row.storeId,
                storeName: row.storeName,
                region: row.region,
                asmName: row?.asmName,
                data: {}
              });
            }

            const changedDateFormat = moment(row.auditDate, 'DD-MM-YYYY').format('MM-DD-YYYY');
            const auditYear = moment(changedDateFormat).year();
            const auditMonth = moment(changedDateFormat).month();
            const yearMonthKey = `${monthNames[auditMonth].toString().slice(0, 3)}'${auditYear.toString().slice(-2)}`;
            dataMap.get(key).data[yearMonthKey] = {
              score: (row.score * 100).toFixed(2) + '%',
              status: row.status,
              expireUnits: row.expireUnits,
              expireSku: row.expireSku
            };
          });

          let index = 1;
          dataMap.forEach((value, key) => {
            csv += `"${index}",`;
            csv += `"${value.checklistName}",`;
            csv += `"${value.storeId}",`;
            csv += `"${value.storeName}",`;
            csv += `"${value.region}",`;
            csv += `"${value?.asmName}",`;

            yearWiseMonths.forEach(yearMonth => {
              if (value.data[yearMonth]) {
                const { score, status, expireUnits, expireSku } = value.data[yearMonth];
                csv += `${score},${status},${expireUnits},${expireSku}`;
              } else {
                csv += '-,-,-,-';
              }
              csv += ',';
            });

            csv += '\n';
            index++;
          });
        };

        if (storeRows.length > 0) {
          csv += `${getHeading('STORE')}\n`;
          processRows(storeRows);
        }
        if (dcrcRows.length > 0) {
          csv += `\n${getHeading('DCRC')}\n`;
          processRows(dcrcRows);
        }
        if (dcRows.length > 0) {
          csv += `\n${getHeading('DC')}\n`;
          processRows(dcRows);
        }

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Summary Report.csv';
        anchor.click();
      }
    },
    keyPointReports(newValue) {
      function replaceValue(Value) {
        let result =
          Value && typeof Value !== 'string' ? Value : Value ? Value.replaceAll('\n', '').replaceAll(',', '') : '';
        return result;
      }

      if (newValue.length > 0) {
        let csv = '';
        csv += `"Sr No","Store Code","Store Name","Region","Audit Date","Audit Observation","Auditors Recommendation","Department Or Category","FirstPoint Responsible","Store Remarks","Tentative ClosureDate"\n`;
        newValue.forEach((item, index) => {
          csv += `"${item.sno}","${item.storeCode}","${replaceValue(item.storeName)}","${replaceValue(item.region)}","${
            item.auditDate
          }","${replaceValue(item.auditObservation)}","${replaceValue(item.auditorsRecommendation)}","${replaceValue(
            item.departmentOrCategory
          )}","${replaceValue(item.firstPointResponsible)}","${replaceValue(item.storeRemarks)}","${
            item.tentativeClosureDate
          }"\n`;
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Key-Point Report.csv';
        anchor.click();
      }
    }
  }
};
</script>
