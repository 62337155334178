<template>
  <div class="page-class">
    <checklist-v-2-fill-box
      :checklistName="checklistName"
      :columns="columns"
      :rows="finalCheckpoints ? finalCheckpoints : []"
      :categoriesAndDepartments="categoriesAndDepartments"
      :showUpload="true"
      :format="this.$route.query.type"
      :ID="this.$route.params.id"
      :checklistStores="checklistStores"
      :isNewChecklist="isNewChecklist"
      :roleOptions="roleOptions"
      emptyText="no checkpoint found"
      uploadText="Upload Checkpoints"
      :onlySavedReport="onlySavedReport"
      @show-upload="onUpload"
    >
    </checklist-v-2-fill-box>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import _ from 'lodash';
import Vue from 'vue';
import csv from 'csvtojson';
import ChecklistV2FillBox from '../../components/ChecklistV2/ChecklistFillPart/ChecklistV2FillBox.vue';

export default {
  name: 'Checklist_V2_Fill',
  components: {
    ChecklistV2FillBox
  },

  async mounted() {
    this.id = this.$route.params.id;
    if (this.$route.query.type === 'report') {
      this.getUserFilledCheckpoints({
        reportId: this.$route.params.id
      });
      setTimeout(() => {
        this.getOne({
          id: this.$route.query.checklistID,
          router
        });
      }, 1000);
    } else {
      this.getOne({
        id: this.id,
        router
      });
    }
  },
  data() {
    return {
      formType: '',
      isNewChecklist: false,
      roleOptions: [
        { value: null, text: 'Select Role' },
        { value: 'QUALITY_AUDIT_HEAD', text: 'Quality Head' },
        { value: 'QUALITY_AUDIT_MANAGER', text: 'Quality Manager' },
        { value: 'QUALITY_AUDIT_EXECUTIVE', text: 'Quality Executive' }
      ],
      id: null,
      checklistStores: [],
      checklistName: '',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'serialNumber',
          width: '3%'
        },
        // {
        //   type: 'row_num',
        //   headerText: 'Category',
        //   class: { ' text-center': true },
        //   textKey: 'category',
        //   width: '5%'
        // },
        {
          type: 'row_num',
          headerText: 'Question',
          class: { ' text-center': true },
          textKey: 'questionText',
          width: '23%'
        },

        // {
        //   type: 'image',
        //   headerText: 'Image Mandatory',
        //   class: { ' text-center': true },
        //   textKey: 'isImageMandatory',
        //   width: '7%'
        // },
        // {
        //   type: 'image',
        //   headerText: 'Image',
        //   class: { ' text-center': true },
        //   textKey: 'picture',
        //   width: '7%'
        // },

        {
          type: 'row_num',
          headerText: 'Auditor Observations',
          class: { ' text-center': true },
          textKey: 'response',
          width: '14%'
        },

        {
          type: 'row_num',
          headerText: 'Auditor Recommendation',
          class: { ' text-center': true },
          textKey: 'auditorsRecommendation',
          width: '14%'
        },
        {
          type: 'row_num',
          headerText: 'Key Point',
          class: { ' text-center': true },
          textKey: 'keyPoint',
          width: '4%'
        },
        {
          type: 'image',
          headerText: 'Dept',
          class: { ' text-center': true },
          textKey: 'departmentOrCategory',
          width: '12%'
        },
        {
          type: 'row_num',
          headerText: 'Auditee Comment',
          class: { ' text-center': true },
          textKey: 'auditeeComment',
          width: '12%'
        },
        {
          type: 'row_num',
          headerText: 'FPR Designation',
          class: { ' text-center': true },
          textKey: 'fprAndDesignation',
          width: '10%'
        },

        {
          type: 'row_num',
          headerText: 'Closure Date',
          class: { ' text-center': true },
          textKey: 'closureDate',
          width: '8%'
        }
      ],
      rows: [],
      finalCheckpoints: [],
      onlySavedReport: false
    };
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Add New Checklist' : 'Update Checklist';
    },
    ...mapState('checklist', ['checklist']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('checklistFill', ['loading']),
    ...mapState('checklistV2Fill', [
      'loading',
      'fillingCheckpoints',
      'userFilledCheckpoints',
      'checkpointResponse',
      'categoriesAndDepartments',
      'lastMonthFilledChecklist'
    ])
  },
  methods: {
    ...mapActions('checklistFill', ['getCheckpoints']),
    ...mapActions('checklistV2Fill', ['getOne', 'postOne', 'patchOne', 'getUserFilledCheckpoints']),
    convertIntoUnderScore(str) {
      return str.split(',').join('\\-');
    },
    convertBackToComma(str) {
      return str.split('\\-').join(',');
    },
    async onUpload() {
      await Vue.swal({
        title: 'Upload file',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDenied) {
          this.createCsv();
        }
        if (result.value) {
          if (result.value.name === `${this.checklistName}-checkpoints.csv`) {
            const reader = new FileReader();
            reader.onload = async () => {
              csv({
                noheader: true,
                output: 'csv'
              })
                .fromString(reader.result)
                .then(async csvRow => {
                  const inputs = csvRow.slice(1, csvRow.length).map(i => {
                    const values = i.reduce((acc, curr, index) => {
                      if (index === 0) {
                        acc.category = curr;
                      }
                      if (index === 1) {
                        acc.subCategory = curr;
                      }
                      if (index === 2) {
                        acc.questionText = this.convertBackToComma(curr);
                      }
                      if (index === 3) {
                        acc.checkpointType = curr;
                      }
                      if (index === 4) {
                        const possibleValues = ['Yes', 'No', 'yes', 'no', 'YES', 'NO'];
                        if (possibleValues.includes(curr)) {
                          acc.booleanAnswer = curr.toUpperCase();
                        } else {
                          acc.booleanAnswer = null;
                        }
                      }
                      if (index === 5) {
                        acc.target = curr;
                      }
                      if (index === 6) {
                        acc.actual = curr;
                      }
                      if (index === 7) {
                        acc.remarks = curr;
                      }

                      if (acc.checkpointType === 'BOOLEAN') {
                        acc.target = '';
                        acc.actual = '';
                        // acc.remarks = '';
                      } else if (acc.checkpointType === 'TARGET') {
                        acc.booleanAnswer = '';
                      }
                      return acc;
                    }, {});
                    return values;
                  });
                  this.finalCheckpoints = this.finalCheckpoints.map((item, index) => {
                    return {
                      ...item,
                      category: inputs[index].category,
                      subCategory: inputs[index].subCategory,
                      target: inputs[index].target ? inputs[index].target : '',
                      booleanAnswer: inputs[index].booleanAnswer ? inputs[index].booleanAnswer : '',
                      actual: inputs[index].actual ? inputs[index].actual : '',
                      remarks: inputs[index].remarks ? inputs[index].remarks : ''
                      // checkpointType: inputs[index].checkpointType : ''
                    };
                  });
                });
            };
            reader.readAsBinaryString(result.value);
          } else {
            Vue.swal({
              title: 'Access Denied',
              text: 'Please upload correct file',
              type: 'error',
              confirmButtonText: 'Ok',
              icon: 'error',
              background: this.isDarkMode ? '#464d54' : '#ffffff',
              customClass: {
                title: this.isDarkMode ? 'text-light' : 'text-dark',
                content: this.isDarkMode ? 'text-light' : 'text-dark'
              }
            });
          }
        }
      });
    },
    compareArrays(filledCheckpoint, notFilledCheckpoints) {
      if (filledCheckpoint.length > 0 && notFilledCheckpoints.length > 0) {
        const filledArray = filledCheckpoint.map(i => i?.checkpoint);
        if (filledArray.length >= notFilledCheckpoints.length) {
          this.onlySavedReport = true;
        }
        const abc = notFilledCheckpoints
          .filter(item => {
            if (filledArray.includes(item._id)) {
              return false;
            }
            return true;
          })
          .map(checkpoint => {
            return {
              ...checkpoint,
              response: '',
              auditObservation: '',
              auditeeComment: '',
              auditorsRecommendation: '',
              departmentOrCategory: '',
              fprAndDesignation: '',
              questionWeightAge: checkpoint.weightAge,
              closeDateValue: ''
            };
          });
        return abc;
      }
    }
  },
  watch: {
    fillingCheckpoints(newValue) {
      if (newValue.length > 0) {
        this.checklistName = newValue[0]?.checkListName;
        if (this.$route.query.type === '' || this.$route.query.type === undefined) {
          this.isNewChecklist = true;
          this.finalCheckpoints = newValue
            .map(checkpoint => {
              const calculation = this.lastMonthFilledChecklist?.checklistData?.find(
                item => item.checkpoint === checkpoint._id
              );
              return {
                ...checkpoint,
                response: calculation?.response || '',
                questionWeightAge: checkpoint.weightAge,
                auditObservation: calculation?.auditObservation || '',
                auditorsRecommendation: calculation?.auditorsRecommendation || '',
                auditeeComment: '',
                departmentOrCategory: '',
                fprAndDesignation: '',
                closureDate: ''
              };
            })
            .sort((a, b) => a.serialNumber - b.serialNumber);
        } else {
          const tempAllCheckpoints = newValue.map(checkpoint => {
            return {
              ...checkpoint,
              response: '',
              auditObservation: '',
              auditeeComment: '',
              questionWeightAge: checkpoint.weightAge,
              auditorsRecommendation: '',
              departmentOrCategory: '',
              fprAndDesignation: '',
              closeDateValue: ''
            };
          });
          this.finalCheckpoints = [
            ...this.userFilledCheckpoints,
            ...this.compareArrays(this.userFilledCheckpoints, tempAllCheckpoints)
          ].sort((a, b) => a.serialNumber - b.serialNumber);
        }
      }
    },
    checkpointResponse(newValue) {
      if (newValue.length > 0 && this.fillingCheckpoints.length > 0) {
        this.finalCheckpoints = [...newValue, ...this.compareArrays(newValue, this.fillingCheckpoints)].sort(
          (a, b) => a.serialNumber - b.serialNumber
        );
      }
    },
    checklist(value) {
      const stores = value.storeids;
      this.checklistStores = stores.length > 0 ? stores : [];
    }
  }
};
</script>
