<script src="//unpkg.com/v-viewer/dist/v-viewer.js"></script>

<template>
  <div>
    <div v-if="!loading">
      <b-row class="my-4 d-flex align-items-center px-1">
        <b-col lg="4" sm="12">
          <div>
            <h2>{{ checklistName }}</h2>
          </div>
        </b-col>
        <b-col lg="4" sm="12">
          <div class="mx-2 text-center">
            <div>
              <b>Store Name : {{ storeData.storeName !== undefined ? storeData.storeName : '' }}</b>
            </div>
            <div>
              <b>Store Id : {{ storeData.storeid !== undefined ? storeData.storeid : '' }}</b>
            </div>
          </div>
        </b-col>
        <b-col lg="4" sm="12" class="text-right">
          <b-button
            class="mx-2"
            v-if="!handleDisableReleaseReport()"
            @click="findCheckpointChange()"
            size="sm"
            :disabled="false"
            :variant="isDarkMode ? 'outline-success' : 'success'"
          >
            <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save Checkpoints
          </b-button>

          <b-button
            v-if="isFoodSafetyTeam"
            size="sm"
            @click="handleSendReportBtn()"
            :disabled="isDisabled"
            :variant="isDarkMode ? 'outline-success' : 'success'"
          >
            <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Send Report
          </b-button>

          <b-button
            class="mx-2"
            @click="handleReleaseBtn()"
            size="sm"
            v-if="isFoodSafetyAdmin || isFoodSafetyManager"
            :disabled="(isFoodSafetyManager && this.checklistCreatedByID === this.user.id) || isDisabled"
            :variant="isDarkMode ? 'outline-success' : 'success'"
          >
            <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Release Report
          </b-button>
          <!-- <b-button
            class="mx-2"
            @click="submitChecklist({ type: 'release' })"
            size="sm"
            v-if="isFoodSafetyAdmin || isFoodSafetyManager"
            :disabled="(isFoodSafetyManager && this.checklistCreatedByID === this.user.id) || isDisabled"
            :variant="isDarkMode ? 'outline-success' : 'success'"
          >
            <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Release Report
          </b-button> -->
          <b-button
            class="mx-2"
            v-if="!handleDisableNotSM()"
            @click="handleSaveChangedBySm()"
            size="sm"
            :disabled="false"
            :variant="isDarkMode ? 'outline-light' : 'secondary'"
          >
            <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save Changes
          </b-button>
          <b-button
            :variant="isDarkMode ? 'outline-primary' : 'primary'"
            class="mx-2"
            v-if="!handleDisableNotSM()"
            @click="handleClose()"
            size="sm"
            :disabled="false"
          >
            <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Send & Close
          </b-button>
          <b-button size="sm" :variant="isDarkMode ? 'outline-warning' : 'warning'" :to="{ path: `/checklistV2_Fill` }">
            <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
          </b-button>
        </b-col>
      </b-row>

      <!-- Score cards -->
      <!-- v-if="this.$route.query.type === 'report' && storeDetails.storeName !== undefined" -->
      <b-row class="mx-1 my-5 d-flex d-flex justify-content-between">
        <b-col
          lg="3"
          sm="12"
          :class="{ 'custom_shadow mb-2': !isDarkMode, 'mb-2 box-shadow2 dark-div rounded': isDarkMode }"
        >
          <div class="d-flex my-3 justify-content-around align-items-center">
            <div> Store Status </div>
            <div>
              <b-button
                class="box-shadow2"
                :variant="
                  isCriticalResponseSelected === true
                    ? 'danger'
                    : overallPercentage >= 80
                    ? 'success'
                    : overallPercentage < 80 && overallPercentage > 50
                    ? 'warning'
                    : 'danger'
                "
              >
                {{
                  isCriticalResponseSelected === true
                    ? 'Red'
                    : overallPercentage >= 80
                    ? 'Green'
                    : overallPercentage < 80 && overallPercentage > 50
                    ? 'Yellow'
                    : 'Red'
                }}
              </b-button>
            </div>
          </div>
          <div class="table-responsive-md">
            <table
              :class="{
                'table table-hover custom-table': !isDarkMode,
                'table text-light custom-table': isDarkMode
              }"
            >
              <tbody>
                <tr>
                  <td>Store Name</td>
                  <td>{{ storeData.storeName !== undefined ? storeData.storeName : '-' }}</td>
                </tr>
                <tr>
                  <td>Store Manager</td>
                  <td>{{ storeData.storeManagerName !== undefined ? storeData.storeManagerName : '-' }}</td>
                </tr>
                <tr>
                  <td>Audit Date</td>
                  <td>{{
                    isNewChecklist
                      ? handleAuditDate(this.auditDateValue)
                      : auditDetails.auditDate !== undefined
                      ? auditDetails.auditDate
                      : ''
                  }}</td>
                </tr>
                <tr>
                  <td>Auditor</td>
                  <td>{{
                    isNewChecklist ? this.user.name : auditDetails.auditor !== undefined ? auditDetails.auditor : ''
                  }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
        <b-col
          lg="3"
          sm="12"
          :class="{ 'p-2 mx-2 custom_shadow mb-2': !isDarkMode, 'mb-2 box-shadow2 dark-div rounded': isDarkMode }"
        >
          <div class="d-flex my-3 justify-content-around align-items-center">
            <div class="h6"> Region </div>
            <div class="h6">{{ storeDetails.region }}</div>
          </div>
          <div class="table-responsive-md">
            <table
              :class="{
                'table table-bordered table-hover': !isDarkMode,
                'table table-bordered text-light': isDarkMode
              }"
            >
              <tbody>
                <tr class="">
                  <td colspan="2" class="text-center">Grading</td>
                </tr>
                <tr>
                  <td>Green</td>
                  <td>>= 80%</td>
                </tr>
                <tr>
                  <td>Yellow</td>
                  <td>50% - 79%</td>
                </tr>
                <tr>
                  <td>Red</td>
                  <td> -> 50%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
        <b-col
          lg="5"
          sm="12"
          :class="{ 'custom_shadow mb-2': !isDarkMode, 'mb-2 box-shadow2 dark-div rounded': isDarkMode }"
        >
          <div class="d-flex my-3 justify-content-around align-items-center">
            <div class="h6"> Score </div>
            <div class="h6">
              <b-button
                class="btn-lg box-shadow2"
                :variant="
                  isCriticalResponseSelected === true
                    ? 'danger'
                    : overallPercentage >= 80
                    ? 'success'
                    : overallPercentage < 80 && overallPercentage > 50
                    ? 'warning'
                    : 'danger'
                "
              >
                {{ overallPercentage }}%</b-button
              >
            </div>
          </div>
          <div class="table-responsive-md table-wrap">
            <table
              :class="{
                'table table-bordered table-hover': !isDarkMode,
                'table table-bordered text-light': isDarkMode
              }"
            >
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Achievable</th>
                  <th scope="col">Achieved</th>
                  <th scope="col">Percent</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, key) in reportScoreCard" :key="key">
                  <td>{{ key }}</td>
                  <td>{{ item.totalWeightAge }}</td>
                  <td>{{ item.totalScore }}</td>
                  <td>{{
                    item.percentage === 100 || item.percentage === 0 ? item.percentage : item.percentage.toFixed(2)
                  }}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th scope="col">Total</th>
                  <th scope="col">{{ totalWeightAge }}</th>
                  <th scope="col">{{ totalScore }}</th>
                  <th scope="col">{{ overallPercentage }}</th>
                </tr>
              </thead>
            </table>
          </div>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-center">
        <div v-if="this.$route.query.type === undefined" sm class="px-0">
          <b-form data-v-step="2" inline>
            <div>
              <h4> Audit Date : </h4>
            </div>
            <b-input-group class="box-shadow2 ml-2 mb-2">
              <b-input-group-append>
                <b-form-datepicker
                  button-only
                  id="start-datepicker"
                  v-if="this.$route.query.type === undefined"
                  placeholder="Start Date"
                  :min="minDate"
                  :disabled="this.$route.query.type === 'report'"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  v-model="auditDateValue"
                ></b-form-datepicker>
              </b-input-group-append>
              <b-form-input
                id="example-input"
                v-model="auditDateValue"
                type="text"
                placeholder="Start Date"
                autocomplete="off"
                :class="{
                  '': !isDarkMode,
                  'bg-dark text-light placeholder-light': isDarkMode
                }"
                disabled
              ></b-form-input>
            </b-input-group>
            <!-- <b-form-input
              type="text"
              class="ml-2"
              v-if="this.$route.query.type !== undefined"
              v-model="auditDateValue"
              :disabled="this.$route.query.type === 'report'"
              placeholder="Audit date"
            ></b-form-input> -->
          </b-form>
        </div>
      </div>

      <b-overlay v-if="allCheckpoints.length < 1" show :variant="isDarkMode ? 'outline-light' : 'outline-secondary'">
        <div :class="{ 'table-row table-row-empty': !isDarkMode, 'table-row-dark table-row-empty': isDarkMode }">
          <div
            :class="{
              'table-column table-column-full text-center': !isDarkMode,
              'table-column-dark table-column-full text-center': isDarkMode
            }"
          >
          </div>
        </div>
      </b-overlay>
      <div v-else class="table_ctr box-shadow2">
        <div
          :class="{
            'table-container border p-3': !isDarkMode,
            'table-container box-shadow2 dark-div p-3': isDarkMode
          }"
        >
          <!-- <div v-for="row in allCheckpoints" :key="row.id">
          <div v-for="column in columns" :key="column.id">
            <div v-if="column.textKey === 'category'"> {{ row[column.textKey] }} </div>
          </div>
           </div> -->
          <div v-for="(audits, category, categoryIndex) in allRows" :key="category">
            <b-row class="d-flex my-4 align-items-center">
              <b-col lg="2" sm="12" class="text-success">
                <h1>{{ categoryIndex + 1 }}. {{ category }}</h1>
              </b-col>
              <b-col lg="1" sm="12" v-if="!isSm">
                <b-form-group>
                  <template v-slot:label> Select: </template>
                  <b-form-select
                    :class="{
                      'box-shadow3   bg-light text-dark': !isDarkMode,
                      'box-shadow2 bg-dark text-light': isDarkMode
                    }"
                    :id="`${category}_response`"
                    :options="['YES', 'NO', 'NA']"
                    placeholder="Choose one"
                    @input="handleCategoryMultiSelect(category)"
                  >
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <div
              :class="{
                'table-row table-row-header': !isDarkMode,
                'table-row-dark dark-div': isDarkMode
              }"
            >
              <div
                v-for="column in columns"
                :key="column.id"
                :class="{
                  'table-column border table-column-first text-center': !isDarkMode,
                  'table-column-dark border table-column-first text-center': isDarkMode
                }"
                class=""
                :style="columnStyle(column)"
                style="font-weight: bold"
              >
                {{ column.headerText }}
              </div>
            </div>

            <!-- <div v-for="(abc, z) in audits" :key="z"> -->
            <div
              :class="{
                'table-row ': !isDarkMode,
                'table-row-dark': isDarkMode
              }"
              v-for="(row, rowIndex) in audits"
              :key="row._id"
            >
              <div
                v-for="(column, index) in columns"
                :key="column.id"
                :class="{
                  ...column.class,
                  'table-column ': !isDarkMode,
                  'table-column-dark': isDarkMode,
                  'table-column-first': index === 0,
                  'table-column-last-odd': columns.length === index + 1 && (columns.length - 1) % 2 === 1
                }"
                :style="columnStyle(column)"
              >
                <slot v-if="column.slotKey" :name="column.slotKey" v-bind:row="row"></slot>
                <div v-if="column.htmlKey" v-html="row[column.htmlKey]"></div>
                <div v-if="column.urlKey"
                  ><a :href="row[column.urlKey]" target="_blank">{{ row[column.urlKey] }}</a></div
                >

                <span
                  v-if="
                    column.textKey &&
                    column.textKey != 'response' &&
                    column.textKey != 'rowNum' &&
                    column.textKey != 'picture' &&
                    column.textKey != 'keyPoint' &&
                    column.textKey != 'departmentOrCategory' &&
                    column.textKey != 'auditorsRecommendation' &&
                    column.textKey != 'fprAndDesignation' &&
                    column.textKey != 'auditeeComment' &&
                    column.textKey != 'closureDate' &&
                    column.textKey != 'isImageMandatory'
                  "
                  class="span-text"
                  >{{ row[column.textKey] }}</span
                >
                <div v-if="column.textKey === 'rowNum'">{{ `${categoryIndex + 1}.${rowIndex + 1}` }} </div>
                <div v-if="column.textKey === 'keyPoint'">
                  <input
                    :class="{
                      'input_checkbox ': !isDarkMode,
                      'bg-dark text-light placeholder-light': isDarkMode
                    }"
                    type="checkbox"
                    v-model="row.keyPoint"
                    :id="`${row._id}_keyPoint`"
                    :disabled="handleDisableReleaseReport()"
                  />
                </div>
                <!-- <div
                  v-viewer
                  v-if="column.type === 'image' && typeof row[column.textKey] != 'string' && row[column.textKey]"
                >
                  <div>
                    <template>
                      <div v-for="image in row[column.textKey]" :key="image" class="imageview">
                        <img :src="image" class="image" />
                      </div>
                    </template>
                  </div>
                </div> -->

                <div v-if="column.textKey === 'isImageMandatory'">
                  {{ row[column.textKey] ? 'Yes' : 'No' }}
                </div>
                <div v-if="column.textKey === 'departmentOrCategory'">
                  <multiselect
                    :id="`${row._id}_departmentOrCategory`"
                    placeholder="Select"
                    v-model="row.departmentOrCategory"
                    :disabled="handleDisableReleaseReport() || !row.keyPoint"
                    :options="categoriesAndDepartments.departments || []"
                    :class="{
                      'box-shadow3 bg-light text': !isDarkMode,
                      darkMode: isDarkMode
                    }"
                  ></multiselect>
                </div>

                <link href="//unpkg.com/viewerjs/dist/viewer.css" rel="stylesheet" />
                <div v-if="column.textKey === 'response'">
                  <b-col>
                    <multiselect
                      :id="`${row._id}_response`"
                      placeholder="Select"
                      :disabled="handleDisableReleaseReport()"
                      :options="handleResponseAnswers(row)"
                      v-model="row.response"
                      :allow-empty="false"
                      :class="{
                        'box-shadow3 bg-light text': !isDarkMode,
                        darkMode: isDarkMode
                      }"
                    ></multiselect>
                    <b-form-textarea
                      :id="`${row._id}_auditObservation`"
                      rows="3"
                      :disabled="handleDisableReleaseReport()"
                      required
                      :value="row.auditObservation"
                      placeholder="Observations...."
                      :class="{
                        'box-shadow3': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                    ></b-form-textarea>
                  </b-col>
                </div>

                <div v-if="column.textKey === 'auditorsRecommendation'">
                  <b-form-textarea
                    :id="`${row._id}_auditorsRecommendation`"
                    rows="4"
                    :disabled="handleDisableReleaseReport()"
                    required
                    :value="row.auditorsRecommendation"
                    placeholder="Add recommendation..."
                    :class="{
                      'box-shadow3': !isDarkMode,
                      'bg-dark text-light placeholder-light': isDarkMode
                    }"
                  ></b-form-textarea>
                </div>
                <div v-if="column.textKey === 'auditeeComment'">
                  <b-form-textarea
                    :id="`${row._id}_auditeeComment`"
                    rows="4"
                    :value="row.auditeeComment"
                    required
                    :disabled="handleDisableNotSM()"
                    placeholder="Add Comment..."
                    :class="{
                      'box-shadow3 ': !isDarkMode,
                      'bg-dark text-light placeholder-light': isDarkMode
                    }"
                  ></b-form-textarea>
                </div>
                <div v-if="column.textKey === 'fprAndDesignation'">
                  <b-form-textarea
                    :id="`${row._id}_fprAndDesignation`"
                    rows="4"
                    :disabled="handleDisableNotSM()"
                    required
                    :value="row.fprAndDesignation"
                    placeholder="Add Response..."
                    :class="{
                      'box-shadow3': !isDarkMode,
                      'bg-dark text-light placeholder-light': isDarkMode
                    }"
                  ></b-form-textarea>
                </div>
                <div v-if="column.textKey === 'closureDate'">
                  <b-input-group class="box-shadow2 ml-2 mb-2 pr-2">
                    <b-input-group-append>
                      <b-form-datepicker
                        button-only
                        :id="`${row._id}_closureDate`"
                        v-model="row.closureDate"
                        :min="minDate"
                        :disabled="handleDisableNotSM()"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-form-input
                      :id="`${row._id}_closureDate`"
                      v-model="row.closureDate"
                      type="text"
                      placeholder="Close Date"
                      autocomplete="off"
                      :class="{
                        '': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                      disabled
                    ></b-form-input>
                  </b-input-group>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </div>

          <div
            v-if="!loading && allCheckpoints.length === 0"
            :class="{ 'table-row table-row-empty': !isDarkMode, 'table-row-dark table-row-empty': isDarkMode }"
          >
            <div
              :class="{
                'table-column table-column-full text-center': !isDarkMode,
                'table-column-dark table-column-full text-center': isDarkMode
              }"
              >{{ emptyText }}</div
            >
          </div>
          <b-overlay v-if="loading" show :variant="isDarkMode ? 'outline-light' : 'outline-secondary'">
            <div :class="{ 'table-row table-row-empty': !isDarkMode, 'table-row-dark table-row-empty': isDarkMode }">
              <div
                :class="{
                  'table-column table-column-full text-center': !isDarkMode,
                  'table-column-dark table-column-full text-center': isDarkMode
                }"
              >
              </div>
            </div>
          </b-overlay>
        </div>
      </div>

      <b-row class="justify-content-center mt-3">
        <b-col sm="12" lg="4">
          <template> Number Of Expiry UNIT </template>
          <b-form-input
            :class="{
              'box-shadow3 bg-light text-dark': !isDarkMode,
              'bg-dark text-light placeholder-light': isDarkMode
            }"
            id="input-expireUnit"
            type="number"
            maxLength="3"
            :disabled="isSm"
            v-model="numberOfExpiryUnit"
            placeholder="Enter..."
          ></b-form-input>
        </b-col>
        <b-col sm="12" lg="4">
          <b-form-group id="group-refCode" label-for="input-refCode">
            <template class=""> Number Of Expiry SKU </template>
            <b-form-input
              :class="{
                'box-shadow3 bg-light text-dark': !isDarkMode,
                'bg-dark text-light placeholder-light': isDarkMode
              }"
              id="input-expireSku"
              v-model="numberOfExpireSKU"
              type="number"
              :disabled="isSm"
              maxLength="3"
              placeholder="Enter..."
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col sm="12" lg="8">
          <b-form-group id="group-refCode" label-for="input-refCode">
            <template>Instruction/Comments </template>
            <b-form-textarea
              rows="4"
              id="input_instructionOrComment"
              :options="roleOptions"
              :disabled="isSm"
              placeholder="Enter Instructions"
              :value="instructionOrComment"
              :class="{
                'box-shadow3': !isDarkMode,
                'bg-dark text-light placeholder-light': isDarkMode
              }"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>

      <b-modal
        id="modal-lg"
        :cancel-disabled="true"
        size="lg"
        v-model="modalVisible"
        title="Enter Details"
        :header-bg-variant="isDarkMode ? 'dark' : 'light'"
        :header-text-variant="isDarkMode ? 'light' : 'dark'"
        :body-bg-variant="isDarkMode ? 'dark' : 'light'"
        :body-text-variant="isDarkMode ? 'light' : 'dark'"
        :footer-bg-variant="isDarkMode ? 'dark' : 'light'"
      >
        <div class="">
          <b-row>
            <!-- <b-col>
              <div class="mx-2 my-1">
                <template class=""> Enter emails if any (separated by commas) </template>
              </div>
              <b-form-input
                class="ml-2 mb-2"
                id="input-name"
                type="text"
                v-model="emailArray"
                placeholder="Enter emails"
              ></b-form-input>
            </b-col> -->

            <b-col>
              <b-form-group id="group-refCode" label-for="input-refCode">
                <div class="mx-2 my-1">
                  <template class="">Send Report To </template>
                </div>
                <b-form-select
                  :class="{
                    'box-shadow3 bg-light text-dark': !isDarkMode,
                    'box-shadow2 bg-dark text-light': isDarkMode
                  }"
                  id="input-critical"
                  :options="roleOptions"
                  placeholder="Select Send to"
                  v-model="roleSendTo"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- <b-row>
          <div class="ml-2 mb-2">
            <div class="mx-2 my-1">
              <template class=""> Enter Emails (if any separated by comms) </template>
            </div>
            <b-form-input type="text" v-model="emailArray"> </b-form-input>
          </div>
        </b-row> -->
        </div>

        <template #modal-footer>
          <div v-if="loading" class="">
            <div class="text-center">
              <span :class="{ 'spinner spinner-black': !isDarkMode, 'spinner spinner-white': isDarkMode }"></span>
            </div>
          </div>
          <button
            :disabled="roleSendTo !== '' && roleSendTo !== null ? false : true"
            @click="submitChecklist({ type: '' })"
            :variant="isDarkMode ? 'outline-success' : 'success'"
            >Send</button
          >
          <button @click="modalVisible = false" :variant="isDarkMode ? 'outline-danger' : 'danger'">Cancel</button>
        </template>
      </b-modal>

      <!-- <b-row class="mt-4">
        <b-col class="text-right">
          <b-button
            class="mx-2"
            v-if="!handleDisableReleaseReport()"
            @click="findCheckpointChange()"
            size="sm"
            :disabled="false"
          >
            <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save Checkpoints
          </b-button>
          <b-button
            class="mx-2"
            v-if="!handleDisableNotSM()"
            @click="handleSaveChangedBySm()"
            size="sm"
            :disabled="false"
          >
            <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save Changes
          </b-button>
          <b-button class="mx-2" v-if="!handleDisableNotSM()" @click="handleClose()" size="sm" :disabled="false">
            <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Send & Close
          </b-button>
          <b-button
            v-if="isFoodSafetyTeam"
            @click="modalVisible = true"
            size="sm"
            :disabled="isDisabled"
            variant="success"
          >
            <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Send Report
          </b-button>
          <b-button
            @click="submitChecklist({ type: 'release' })"
            size="sm"
            v-if="isFoodSafetyAdmin || isFoodSafetyManager"
            :disabled="(isFoodSafetyManager && this.checklistCreatedByID === this.user.id) || isDisabled"
            variant="success"
            class="mx-2"
          >
            <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Release Report
          </b-button>
          <b-button class="" size="sm" variant="warning" :to="{ path: `/checklistV2_Fill` }">
            <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
          </b-button>
        </b-col>
      </b-row> -->
      <b-row class="mt-4">
        <b-col class="text-right">
          <b-button
            class="mx-2"
            v-if="!handleDisableReleaseReport()"
            @click="findCheckpointChange()"
            size="sm"
            :disabled="false"
            :variant="isDarkMode ? 'outline-success' : 'success'"
          >
            <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save Checkpoints
          </b-button>

          <b-button
            v-if="isFoodSafetyTeam"
            size="sm"
            @click="handleSendReportBtn()"
            :disabled="isDisabled"
            :variant="isDarkMode ? 'outline-success' : 'success'"
          >
            <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Send Report
          </b-button>

          <b-button
            class="mx-2"
            @click="handleReleaseBtn()"
            size="sm"
            v-if="isFoodSafetyAdmin || isFoodSafetyManager"
            :disabled="(isFoodSafetyManager && this.checklistCreatedByID === this.user.id) || isDisabled"
            :variant="isDarkMode ? 'outline-success' : 'success'"
          >
            <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Release Report
          </b-button>
          <!-- <b-button
            class="mx-2"
            @click="submitChecklist({ type: 'release' })"
            size="sm"
            v-if="isFoodSafetyAdmin || isFoodSafetyManager"
            :disabled="(isFoodSafetyManager && this.checklistCreatedByID === this.user.id) || isDisabled"
                        :variant="isDarkMode ? 'outline-success' : 'success'"
          >
            <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Release Report
          </b-button> -->
          <b-button
            class="mx-2"
            v-if="!handleDisableNotSM()"
            @click="handleSaveChangedBySm()"
            size="sm"
            :disabled="false"
            :variant="isDarkMode ? 'outline-primary' : 'primary'"
          >
            <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save Changes
          </b-button>
          <b-button
            class="mx-2"
            v-if="!handleDisableNotSM()"
            @click="handleClose()"
            size="sm"
            :disabled="false"
            :variant="isDarkMode ? 'outline-primary' : 'primary'"
          >
            <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Send & Close
          </b-button>
          <b-button size="sm" :variant="isDarkMode ? 'outline-warning' : 'warning'" :to="{ path: `/checklistV2_Fill` }">
            <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <div class="text-center mt-5">
        <span class="spinner spinner-black"></span>
        <span><strong> Processing Please Wait ....</strong> </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import VueViewer from 'v-viewer';
import Vue from 'vue';
import router from '@/router';

Vue.use(VueViewer);

export default {
  name: 'checklistFillBox',
  data() {
    return {
      instructionOrComment: '',
      categoryResponse: '',
      storeData: '',
      storeDetails: {},
      allCategoriesResponse: {},
      isCriticalResponseSelected: false,
      auditDetails: {},
      reportScoreCard: {},
      modalVisible: false,
      emailArray: '',
      viewType: ['CRITICAL', 'YES', 'NO', 'NA'],
      numberOfExpiryUnit: '',
      numberOfExpireSKU: '',
      roleSendTo: null,
      departmentOrCategory: [
        'Regulatory/License',
        'Private Brand',
        'Cleaning & Sanitation',
        'Dump/DAD/NRGP clearance',
        'Pest Control',
        'Miscellaneous',
        'HR/Uniform policy',
        'VC missing for weighing scale',
        'Fungal infected/insect/damage/stale',
        'Food concessionaires',
        'Underweight/FSSAI non-compliance/Labeling issue',
        'Temp issue in chiller/freezer',
        'Expired products',
        'VC missing for physical weights',
        'FIFO',
        'Category - FnV/FnM/Staples/BFS',
        'Maintenance',
        'Operation'
      ],
      checkpoints: [],
      isCritical: false,
      checklistCreatedByID: '',
      allCheckpoints: [],
      storeObject: '',
      checklistId: '',
      reportId: '',
      isDisabled: true,
      allCategory: [],
      allRows: [],
      rowsStates: {},
      validatingImageMandatory: false,
      filterSelected: null,
      auditDateValue: '',
      reportClosureDateValue: '',
      releaseDateValue: '',
      endDateValue: moment().format('YYYY-MM-DD'),
      searchTerm: null,
      minDate: moment('2020-01-01').format('YYYY-MM-DD'),
      selectedSearchVariable: this.searchItems && this.searchItems[0]
    };
  },
  components: {
    Multiselect
  },
  async mounted() {
    this.getStoreByUser();
    if (this.format === 'report') {
      this.reportId = this.ID;
    }
  },
  props: {
    columns: Array,
    rows: Array,
    format: String,
    ID: String,
    emptyText: String,
    checklistName: String,
    onlySavedReport: Boolean,
    showUpload: Boolean,
    uploadText: String,
    roleOptions: Array,
    isNewChecklist: Boolean,
    categoriesAndDepartments: Object
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Add New Checklist' : 'Update Checklist';
    },

    ...mapState('auth', ['user']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapGetters('auth', ['isFoodSafetyExecutive', 'isFoodSafetyAdmin', 'isFoodSafetyManager', 'isFoodSafetyTeam']),
    ...mapState('cstSubmission', ['store']),
    ...mapGetters('auth', ['isFoodSafetyAdmin', 'isSm']),
    ...mapState('checklistV2Fill', ['loading', 'report', 'storeSelected', 'singleReportData']),
    totalScore() {
      return Object.values(this.reportScoreCard).reduce((acc, categoryData) => acc + categoryData.totalScore, 0);
    },
    totalWeightAge() {
      return Object.values(this.reportScoreCard).reduce((acc, categoryData) => acc + categoryData.totalWeightAge, 0);
    },
    overallPercentage() {
      return this.totalWeightAge > 0 ? parseFloat(((this.totalScore / this.totalWeightAge) * 100).toFixed(2)) : 0;
    }
  },
  watch: {
    store(newValue) {
      if (newValue.length > 0) {
        this.storeObject = newValue.find(store => store.storeid === this.storeSelected)?._id;
        const tempStoreData = newValue.find(store => store.storeid === this.storeSelected);
        if (tempStoreData !== undefined) {
          this.storeData = tempStoreData;
          if (this.isNewChecklist) {
            this.storeDetails = this.storeData;
          }
        }
      }
    },
    reportScoreCard(newValue) {
      if (newValue.length > 0) {
        const abc = newValue.reduce((acc, curr) => {
          acc.totalAchievable += curr.totalScore;
          acc.totalAchieved += curr?.totalWeightAge ? curr.totalWeightAge : 0;
          acc.totalPercentage += curr.percentage;
        }, {});
      }
    },
    singleReportData(newValue) {
      if (Object.keys(newValue).length > 0) {
        this.storeDetails = newValue?.store;
        this.auditDetails = {
          auditor: newValue.submittedByName,
          auditDate: moment(newValue.auditDate).format('DD-MMMM-YYYY')
        };
        this.checklistCreatedByID = newValue?.submittedBy;
        this.auditDateValue = newValue.auditDate;
        this.storeObject = newValue.store._id;
        this.reportClosureDateValue = moment(newValue.reviewDate).format('YYYY-MM-DD');
        this.releaseDateValue = moment(newValue.releaseDate).format('YYYY-MM-DD');
        this.numberOfExpiryUnit = newValue.expireUnits;
        this.numberOfExpireSKU = newValue.expireSKU;
        this.emailArray = newValue?.sendEmailTo?.join(',');
        this.roleSendTo = newValue?.sendReportTo;
        this.storeData = newValue?.store;
        this.instructionOrComment = newValue?.instructionOrComment;
      }
    },
    onlySavedReport(newValue) {
      this.isDisabled = false;
    },
    checkpointResponse(newValue) {
      if (this.allCheckpoints.every(item => item.booleanAnswer !== '' || item.target !== '') && newValue.length) {
        this.isDisabled = false;
      }
    },
    report(newValue) {
      this.reportId = newValue._id;
      this.checklistCreatedByID = newValue?.submittedBy;
    },
    rows(newValue) {
      if (newValue !== undefined && newValue.length > 0) {
        const criticalValidation = newValue.find(item => item.category === 'CRITICAL' && item.response === 'CRITICAL');
        if (criticalValidation !== undefined) {
          this.isCriticalResponseSelected = true;
        } else {
          this.isCriticalResponseSelected = false;
        }
        this.reportScoreCard = newValue.reduce((acc, entry) => {
          const { score, weightAge, category } = entry;
          acc[category] = acc[category] || { totalScore: 0, totalWeightAge: 0 };
          acc[category].totalScore += score ? score : 0;
          acc[category].totalWeightAge += weightAge ? weightAge : 0;
          acc[category].percentage =
            acc[category].totalScore / acc[category].totalWeightAge
              ? (acc[category].totalScore / acc[category].totalWeightAge) * 100
              : 0;
          return acc;
        }, {});
        if (this.format === 'report') {
          this.checklistId = this.$route.query.checklistID;
          const reportId = newValue.find(item => item?.report && item.report !== '').report;
          this.getOneReportData({ id: reportId });
        } else {
          if (newValue[0]?.checklist) {
            this.checklistId = newValue[0]?.checklist;
          } else {
            this.checklistId = newValue[0]?.checklistId;
          }
        }
        const validation = newValue.filter(item => item?.isImageMandatory === true && item?.picture?.length === 0);
        if (validation.length > 0) {
          this.validatingImageMandatory = true;
        }
        this.allCategory = new Set(newValue.map(item => item.category));
        const groupedArrays = {};
        newValue.forEach(item => {
          const category = item.category;
          if (!groupedArrays[category]) {
            groupedArrays[category] = [];
          }
          groupedArrays[category].push(item);
        });
        this.allRows = groupedArrays;
        this.allCheckpoints = newValue.map((item, index) => ({
          ...item,
          rowNum: index + 1
        }));
      }
    }
  },
  methods: {
    ...mapActions('cstSubmission', ['getStoreByUser']),
    ...mapActions('checklistV2Fill', ['generateReport', 'singleCheckpoint', 'releaseReport', 'getOneReportData']),
    handleSendReportBtn() {
      const changedCheckpoints = Object.values(this.allRows)
        .flat()
        .filter(item => item.response !== '');
      if (changedCheckpoints.length === 0) {
        Vue.swal({
          title: 'Checkpoint Save Error',
          text: `Please fill at least one checkpoint`,
          type: 'error',
          icon: 'error',
          confirmButtonText: 'Ok',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        });
        return null;
      } else {
        Vue.swal({
          title: 'Pre confirm',
          text: 'Please save changes before sending, data will be lost if report not saved',
          showCancelButton: true,
          icon: 'success',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Save Checkpoints',
          denyButtonText: 'I have saved checkpoints',
          denyButtonColor: '#3a4',
          showCancelButton: true,
          showDenyButton: true,
          cancelButtonText: 'Close',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        }).then(result => {
          if (result.isDenied) {
            this.modalVisible = true;
          }
          if (result.value) {
            this.findCheckpointChange();
          }
        });
      }
    },

    handleReleaseBtn() {
      Vue.swal({
        title: 'Pre confirm',
        text: 'Please save changes before sending, data will be lost if report not saved',
        showCancelButton: true,
        icon: 'success',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Save Checkpoints',
        denyButtonText: 'I have saved checkpoints',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDenied) {
          this.submitChecklist({ type: 'release' });
        }
        if (result.value) {
          this.findCheckpointChange();
        }
      });
    },
    handleAuditDate(date) {
      if (date !== '') {
        return moment(date).format('DD-MMMM-YYYY');
      }
      return '';
    },
    handleCategoryMultiSelect(category) {
      const abc = this.allRows[category];
      this.allRows[category] = abc.map(item => ({
        ...item,
        response: document.getElementById(`${category}_response`).value
      }));
    },
    handleDisableReleaseReport() {
      if (this.$route.query.from === 'released') {
        return true;
      }
      return false;
    },
    handleDisableNotSM() {
      const isStoreSalesManager = this.user.roles.filter(
        role => role.name === 'STORE_SALES_MANAGER' || role.name === 'DEPARTMENT_MANAGER'
      );
      if (isStoreSalesManager.length) {
        return false;
      }
      return true;
    },
    handleResponseAnswers(row) {
      if (row.category !== '' && row?.category?.toLowerCase() === 'critical') {
        return ['CRITICAL', 'YES', 'NO', 'NA'];
      }
      return ['YES', 'NO', 'NA'];
    },
    columnStyle(column) {
      return { width: column.width };
    },
    clickUpload() {
      this.$emit('show-upload', {});
    },
    checkSaveDisabled() {
      if (this.format === 'report') {
        return true;
      } else if (this.reportId != '') {
        return true;
      } else {
        return false;
      }
    },

    findCheckpointChange() {
      if (this.auditDateValue === '') {
        Vue.swal({
          title: 'Audit Date Required',
          text: `Please provide audit date`,
          type: 'error',
          icon: 'error',
          confirmButtonText: 'Ok',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        });
        return null;
      }
      const changedCheckpoints = Object.values(this.allRows).flat();

      const singleCheckpointValidation = Object.values(this.allRows)
        .flat()
        .filter(item => item.response !== '');

      if (singleCheckpointValidation.length < 1) {
        Vue.swal({
          title: 'Checkpoint Save Error',
          text: `Please fill at least one checkpoint`,
          type: 'error',
          icon: 'error',
          confirmButtonText: 'Ok',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        });
        return null;
      }
      const scoreAndProgress = this.calculateChecklistScoreAndProgress({
        submissions: changedCheckpoints.flat().map(item => {
          return { ...item, score: this.calculateScore(item) };
        }),
        checkpointsCount: this.allCheckpoints.length
      });
      const actualScoreAndProgress = this.calculateChecklistScoreAndProgress({
        submissions: Object.values(this.allRows)
          .flat()
          .map(item => {
            return { ...item, score: this.calculateScore(item) };
          }),
        checkpointsCount: this.allCheckpoints.length
      });

      const keyPointValidation = changedCheckpoints.find(
        item =>
          item.keyPoint === true &&
          (item.departmentOrCategory === '' ||
            document.getElementById(`${item?._id}_auditObservation`).value === '' ||
            document.getElementById(`${item._id}_auditorsRecommendation`).value === '')
      );
      if (keyPointValidation) {
        Vue.swal({
          title: `Key Point Validation Error on checkpoint number ${keyPointValidation.serialNumber} `,
          text: `If key point is selected, please select Dept and fill auditor Observation & Auditor Recommendation`,
          type: 'error',
          icon: 'error',
          confirmButtonText: 'Ok',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        });
        return null;
      }
      let color;
      const criticalValidation = changedCheckpoints.find(
        row => row.category === 'CRITICAL' && row.response === 'CRITICAL'
      );
      const score = actualScoreAndProgress.overallScore * 100;
      if (criticalValidation || score < 50) {
        color = '#ee4035';
      } else if (score >= 80) {
        color = '#52bf90';
      } else {
        color = '#fdf498';
      }
      Vue.swal({
        title: 'Pre confirm',
        text: 'Are you sure you want to save checklist so far. Please check once before save',
        showCancelButton: true,
        icon: 'success',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Submit',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isConfirmed) {
          this.instructionOrComment = document.getElementById('input_instructionOrComment').value;
          this.generateReport({
            reportID: this.reportId,
            params: {
              completed: false,
              checklist: this.checklistId,
              store: this.storeObject,
              reportStatusColor: color,
              expireUnits: this.numberOfExpiryUnit,
              expireSKU: this.numberOfExpireSKU,
              auditDate: this.auditDateValue,
              instructionOrComment: document.getElementById('input_instructionOrComment').value,
              location: {
                lat: null,
                long: null
              }
            },
            singleCheckpoint: {
              checkpoints: changedCheckpoints.map(row => {
                return {
                  checkpoint: this.calculateCheckpointID(row),
                  auditObservation: document.getElementById(`${row._id}_auditObservation`).value,
                  auditorsRecommendation: document.getElementById(`${row._id}_auditorsRecommendation`).value,
                  departmentOrCategory: row.departmentOrCategory,
                  auditeeComment: document.getElementById(`${row._id}_auditeeComment`).value || row.auditeeComment,
                  fprAndDesignation:
                    document.getElementById(`${row._id}_fprAndDesignation`).value || row.fprAndDesignation,
                  questionText: row.questionText,
                  response: row.response,
                  keyPoint: row.keyPoint,
                  category: row.category,
                  picture: row.picture ? row.picture : [],
                  closureDate: row.closureDate,
                  serialNumber: row.serialNumber,
                  questionWeightAge: row.questionWeightAge,
                  score: this.calculateScore(row),
                  weightAge: row.response === 'NA' ? 0 : row.questionWeightAge,
                  createdAt: moment().format(),
                  imageMandatory: row.imageMandatory
                };
              }),
              overallScore: actualScoreAndProgress.overallScore,
              overallProgress: scoreAndProgress.overallProgress,
              reportStatusColor: color,
              expireUnits: this.numberOfExpiryUnit,
              expireSKU: this.numberOfExpireSKU,
              instructionOrComment: document.getElementById('input_instructionOrComment').value
            },
            router,
            redirectUrl: ''
          });
        }
      });
    },
    finalCheckpointChange() {
      const changedCheckpoints = Object.values(this.allRows)
        .flat()
        .filter(item => item.response !== '');
      const keyPointValidation = changedCheckpoints.find(
        item =>
          (item.keyPoint === true || item.response === 'NO') &&
          (document.getElementById(`${item?._id}_auditeeComment`).value === '' ||
            document.getElementById(`${item._id}_fprAndDesignation`).value === '' ||
            item.closureDate === null)
      );
      if (keyPointValidation) {
        Vue.swal({
          title: `Response Validation Error on checkpoint number ${keyPointValidation.serialNumber} `,
          text: `Either keyPoint/no in response has been filled, Auditee Comment  and fill FPR Designation  & Closure Date must be filled in this checkpoint`,
          type: 'error',
          icon: 'error',
          confirmButtonText: 'Ok',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        });
        return true;
      }
      return false;
    },

    handleSaveChangedBySm() {
      Vue.swal({
        title: 'Pre confirm',
        text: 'Please check the data before Save',
        showCancelButton: true,
        icon: 'success',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Save',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isConfirmed) {
          const changedCheckpoints = Object.values(this.allRows)
            .flat()
            .filter(item => item.response !== '');
          const actualScoreAndProgress = this.calculateChecklistScoreAndProgress({
            submissions: Object.values(this.allRows)
              .flat()
              .map(item => {
                return { ...item, score: this.calculateScore(item) };
              }),
            checkpointsCount: this.allCheckpoints.length
          });
          const scoreAndProgress = this.calculateChecklistScoreAndProgress({
            submissions: changedCheckpoints.map(item => {
              return { ...item, score: this.calculateScore(item) };
            }),
            checkpointsCount: this.allCheckpoints.length
          });
          let color;
          const criticalValidation = changedCheckpoints.find(
            row => row.category === 'CRITICAL' && row.response === 'CRITICAL'
          );
          if (criticalValidation !== undefined) {
            color = '#ee4035';
          } else if (this.overallPercentage > 80) {
            color = '#52bf90';
          } else if (this.overallPercentage < 80 && this.overallPercentage > 50) {
            color = '#fdf498';
          } else {
            color = '#ee4035';
          }
          this.generateReport({
            reportID: this.reportId,
            params: {
              completed: false,
              checklist: this.checklistId,
              store: this.storeObject,
              auditDate: this.auditDateValue,
              location: {
                lat: null,
                long: null
              }
            },
            singleCheckpoint: {
              checkpoints: changedCheckpoints.map(row => {
                return {
                  checkpoint: this.calculateCheckpointID(row),
                  auditObservation: document.getElementById(`${row._id}_auditObservation`).value,
                  auditorsRecommendation: document.getElementById(`${row._id}_auditorsRecommendation`).value,
                  departmentOrCategory: row.departmentOrCategory,
                  auditeeComment: document.getElementById(`${row._id}_auditeeComment`).value,
                  fprAndDesignation: document.getElementById(`${row._id}_fprAndDesignation`).value,
                  questionText: row.questionText,
                  response: row.response,
                  keyPoint: row.keyPoint,
                  category: row.category,
                  picture: row.picture ? row.picture : [],
                  closureDate: row.closureDate,
                  serialNumber: row.serialNumber,
                  questionWeightAge: row.questionWeightAge,
                  score: this.calculateScore(row),
                  weightAge: row.response === 'NA' ? 0 : row.questionWeightAge,
                  createdAt: moment().format(),
                  imageMandatory: row.imageMandatory
                };
              }),
              overallScore: actualScoreAndProgress.overallScore,
              overallProgress: scoreAndProgress.overallProgress,
              reportStatusColor: color,
              expireUnits: this.numberOfExpiryUnit,
              expireSKU: this.numberOfExpireSKU,
              instructionOrComment: document.getElementById('input_instructionOrComment').value
            },
            router,
            redirectUrl: ''
          });
          this.releaseReport({
            params: {
              completed: true,
              mailFlag: false,
              store: this.storeObject,
              checklist: this.checklistId,
              auditDate: this.auditDateValue,
              releaseDate: this.releaseDateValue,
              reportClosureDate: null,
              instructionOrComment: document.getElementById('input_instructionOrComment').value,
              expireUnits: this.numberOfExpiryUnit,
              reportStatusColor: color,
              expireSKU: this.numberOfExpireSKU,
              reportId: this.reportId,
              location: {
                lat: null,
                long: null
              }
            },
            router,
            redirectUrl: ''
          });
        }
      });
    },
    handleClose() {
      Vue.swal({
        title: 'Pre confirm',
        text: 'Are you sure you want to close this checklist. Please check the data before close, you would not be able to change once close',
        showCancelButton: true,
        icon: 'success',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Send & Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isConfirmed) {
          const changedCheckpoints = Object.values(this.allRows)
            .flat()
            .filter(item => item.response !== '');
          const actualScoreAndProgress = this.calculateChecklistScoreAndProgress({
            submissions: Object.values(this.allRows)
              .flat()
              .map(item => {
                return { ...item, score: this.calculateScore(item) };
              }),
            checkpointsCount: this.allCheckpoints.length
          });
          const scoreAndProgress = this.calculateChecklistScoreAndProgress({
            submissions: changedCheckpoints.map(item => {
              return { ...item, score: this.calculateScore(item) };
            }),
            checkpointsCount: this.allCheckpoints.length
          });
          let color;
          const criticalValidation = changedCheckpoints.find(
            row => row.category === 'CRITICAL' && row.response === 'CRITICAL'
          );
          if (criticalValidation !== undefined) {
            color = '#ee4035';
          } else if (this.overallPercentage > 80) {
            color = '#52bf90';
          } else if (this.overallPercentage < 80 && this.overallPercentage > 50) {
            color = '#fdf498';
          } else {
            color = '#ee4035';
          }
          this.generateReport({
            reportID: this.reportId,
            params: {
              completed: false,
              checklist: this.checklistId,
              store: this.storeObject,
              auditDate: this.auditDateValue,
              location: {
                lat: null,
                long: null
              }
            },
            singleCheckpoint: {
              checkpoints: changedCheckpoints.map(row => {
                return {
                  checkpoint: this.calculateCheckpointID(row),
                  auditObservation: document.getElementById(`${row._id}_auditObservation`).value,
                  auditorsRecommendation: document.getElementById(`${row._id}_auditorsRecommendation`).value,
                  departmentOrCategory: row.departmentOrCategory,
                  auditeeComment: document.getElementById(`${row._id}_auditeeComment`).value,
                  fprAndDesignation: document.getElementById(`${row._id}_fprAndDesignation`).value,
                  questionText: row.questionText,
                  response: row.response,
                  keyPoint: row.keyPoint,
                  category: row.category,
                  picture: row.picture ? row.picture : [],
                  questionWeightAge: row.questionWeightAge,
                  closureDate: row.closureDate,
                  serialNumber: row.serialNumber,
                  score: this.calculateScore(row),
                  weightAge: row.response === 'NA' ? 0 : row.questionWeightAge,
                  createdAt: moment().format(),
                  imageMandatory: row.imageMandatory
                };
              }),
              overallScore: actualScoreAndProgress.overallScore,
              overallProgress: scoreAndProgress.overallProgress,
              reportStatusColor: color,
              expireUnits: this.numberOfExpiryUnit,
              expireSKU: this.numberOfExpireSKU,
              instructionOrComment: document.getElementById('input_instructionOrComment').value
            },
            router,
            redirectUrl: ''
          });

          this.releaseReport({
            params: {
              completed: true,
              mailFlag: true,
              store: this.storeObject,
              checklist: this.checklistId,
              auditDate: this.auditDateValue,
              releaseDate: this.releaseDateValue,
              reportClosureDate: moment().format('YYYY-MM-DD'),
              expireUnits: this.numberOfExpiryUnit,
              instructionOrComment: document.getElementById('input_instructionOrComment').value,
              reportStatusColor: color,
              expireSKU: this.numberOfExpireSKU,
              reportId: this.reportId,
              location: {
                lat: null,
                long: null
              }
            },
            router,
            redirectUrl: '/foodSafety_Reports'
          });
        }
      });
      if (!this.finalCheckpointChange()) {
        return;
      }

      return;
    },
    async submitChecklist({ type }) {
      if (type !== 'release') {
        this.modalVisible = true;
      }
      let emails = [];
      if (this.emailArray.length > 0) {
        emails = this.emailArray?.split(',').map(item => {
          let position = item.search('@');
          if (position >= 0) {
            const abc = item.trim().slice(0, position);
            return `${abc}@rpsg.in`;
          } else {
            return `${item.trim()}@rpsg.in`;
          }
        });
      }
      let color;
      const changedCheckpoints = Object.values(this.allRows)
        .flat()
        .filter(item => item.response !== '');
      const criticalValidation = changedCheckpoints.find(
        row => row.category === 'CRITICAL' && row.response === 'CRITICAL'
      );
      if (criticalValidation !== undefined) {
        color = '#ee4035';
      } else if (this.overallPercentage >= 80) {
        color = '#52bf90';
      } else if (this.overallPercentage < 80 && this.overallPercentage > 50) {
        color = '#fdf498';
      } else {
        color = '#ee4035';
      }
      this.releaseReport({
        params: {
          completed: type === 'release' ? true : false,
          mailFlag: type === 'release' ? true : false,
          checklist: this.checklistId,
          auditDate: this.auditDateValue,
          releaseDate: type === 'release' ? moment().format('YYYY-MM-DD') : '',
          expireUnits: this.numberOfExpiryUnit,
          expireSKU: this.numberOfExpireSKU,
          instructionOrComment: document.getElementById('input_instructionOrComment').value,
          reportStatusColor: color,
          sendReportTo: this.roleSendTo,
          acknowledgedBy: null,
          store: this.storeObject,
          reportId: this.reportId,
          sendEmailTo: emails,
          location: {
            lat: null,
            long: null
          }
        },
        router,
        redirectUrl: '/foodSafety_Reports'
      });
    },
    calculateChecklistScoreAndProgress({ submissions, checkpointsCount }) {
      let score = 0,
        progress = 0,
        totalWeightage = 0;
      submissions.forEach(item => {
        if (item.score > -1) {
          if (item.response !== 'NA') {
            score += item.score;
            totalWeightage += item.questionWeightAge;
            progress++;
          } else {
            score += 0;
            totalWeightage += 0;
            progress++;
          }
        }
      });

      const overallScore = progress > 0 ? (score * 100) / totalWeightage / 100 : 0;
      const overallProgress = (progress * 100) / checkpointsCount / 100;
      return {
        overallScore,
        overallProgress
      };
    },
    calculateScore(row) {
      if (row.category.toUpperCase() === 'CRITICAL') {
        if (row.response === 'YES') {
          if (row.weightAge !== 0) {
            return row.weightAge;
          }
          return 4;
        } else if (row.response === 'NO') {
          return 0;
        } else {
          return 0;
        }
      } else {
        if (row.response === 'YES') {
          if (row.weightAge !== 0) {
            return row.weightAge;
          }
          return 1;
        } else if (row.response === 'NO') {
          return 0;
        } else {
          return 0;
        }
      }
    },
    calculateCheckpointID(row) {
      if (row.checkpoint) {
        return row.checkpoint;
      } else {
        return row._id;
      }
    }
  }
};
</script>

<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
#div2,
#div4 {
  border: 1px solid black;
  width: 250px;
  margin-bottom: 12px;
}

.custom_shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 12px;
}

.custom-table {
  border: none;
}

.custom-table th,
.custom-table td {
  border: none;
}

.table-wrap {
  height: 300px;
  overflow-y: auto;
}

#div2 {
  overflow-x: scroll;
}

#div4 {
  overflow-x: auto;
}

.table-isPrinted {
  background: #f5c9a9 !important;
}

.component-tablebox {
  font-size: 0.9rem;
}

input {
  width: 100%;
  margin: 1px;
}

.input_checkbox {
  /* width: 30px; */
  height: 20px;
}

.imageview {
  display: inline-flex;
  margin: 2px;
}

img {
  width: 3em !important;
  height: 4.5em !important;
  transition: transform 0.2s;
}

.table-top-wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: left;
}

.table_ctr {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  overflow-x: scroll;
}

.table-container {
  display: block;
  width: 2400px;
  overflow-y: auto;
}

.row-total-count {
  margin-bottom: 0.5rem;
}

.row-total-count span {
  font-weight: bold;
}

.table-row {
  display: flex;
  flex-flow: row wrap;
  border-left: solid 1px #d9d9d9;
  transition: 0.5s;
  margin-left: 0;
  margin-right: 0;
}

.table-row:first-of-type {
  border-top: solid 1px #d9d9d9;
  border-left: solid 1px #d9d9d9;
  border-bottom: solid 2px #d9d9d9;
}

.table-column {
  padding: 0.5em 0.5em;
  border-right: solid 1px #d9d9d9;
  border-bottom: solid 1px #d9d9d9;
  word-wrap: break-word;
}

.table-columns {
  display: flex;
  flex-flow: column wrap;
  width: 75%;
  padding: 0;
}

.table-columns .table-column {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 0;
  border: 0;
  border-bottom: solid 1px #d9d9d9;
}

.table-columns .table-column:hover {
  background: #f5f5f5;
  transition: 500ms;
}

.table-row:first-of-type .table-column {
  background: #fff;
  color: #000;
  border-color: #d9d9d9;
  font-weight: bold;
}

.table-row:nth-child(odd) .table-column {
  background: #f4f2f1;
}

.table-row:hover {
  background: #f5f5f5;
  transition: 500ms;
}

.table-column-full {
  width: 100%;
}

.table-column-rowspan {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
}

.flex-cell {
  text-align: center;
  padding: 0.5em 0.5em;
  border-right: solid 1px #d9d9d9;
}

@media all and (max-width: 767px) {
  .table-row {
    border-bottom: solid 2px #d9d9d9;
    border-right: solid 1px #d9d9d9;
  }

  .table-row .table-column {
    width: 50% !important;
    border: 0;
    text-align: left !important;
    background: #fff;
  }

  .table-row .table-column.table-column-first {
    border-bottom: solid 1px #d9d9d9;
    width: 100% !important;
  }

  .table-row .table-column.table-column-last-odd {
    width: 100% !important;
  }
}

@media all and (max-width: 430px) {
  .table-column {
    width: 100%;
  }

  .column .table-column {
    border-bottom: solid 1px;
  }

  .table-column.table-column-first {
    width: 100%;
    border-bottom: solid 1px #d9d9d9;
  }

  .table-row .table-column {
    width: 100% !important;
    border-bottom: 0;
    text-align: left !important;
  }

  .table-row .table-column:last-of-type {
    border-bottom: solid 1px #d9d9d9;
  }

  .table-columns {
    width: 100%;
  }

  .table-columns.table-column {
    border-bottom: solid 1px #d9d9d9;
  }

  .flex-cell {
    width: 100%;
  }
}
</style>
