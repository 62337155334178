<template>
  <div>
    <div v-if="loading || !formLoaded">
      <span class="spinner"></span>
    </div>
    <div v-if="!loading && formLoaded">
      <b-form
        @submit.stop.prevent="onSubmit"
        :class="{
          'p-3 box-shadow2 rounded': !isDarkMode,
          'dark-div p-3 box-shadow2 rounded': isDarkMode
        }"
      >
        <b-form-group id="group-products" label-for="input-products">
          <template v-slot:label>
            Store Id
            <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="storeids"
            placeholder="Select Store Id"
            label="text"
            track-by="value"
            :options="stores"
          ></multiselect>
        </b-form-group>

        <template>
          Date Of Calling
          <span class="text-danger">*</span>
        </template>

        <div class="calender">
          <b-form-datepicker
            id="datepicker"
            placeholder=" Date"
            :min="minDate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            v-model="dateValue"
            class="ml-2 mb-2"
          ></b-form-datepicker>
        </div>

        <b-form-group id="group-name" label-for="input-name">
          <template v-slot:label>
            Customer Name
            <span class="text-danger">*</span>
          </template>

          <b-form-input
            id="input-name"
            type="text"
            v-model="form.customerName"
            placeholder="Enter Customer Name"
          ></b-form-input>

          <b-form-invalid-feedback id="input-name-invalid">Please enter customer name.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="group-mobile" label-for="input-mobile">
          <template v-slot:label>
            Mobile
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="input-mobile"
            v-model="form.mobile"
            type="number"
            placeholder="Enter Mobile Number"
            :state="$v.form.mobile.$dirty ? !$v.form.mobile.$error : null"
          ></b-form-input>

          <b-form-text id="input-mobile-help">Mobile Number must be a valid 10 digit long number.</b-form-text>

          <b-form-invalid-feedback id="input-email-invalid">Please enter valid mobile number.</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="group-product" label-for="input-product">
          <template v-slot:label>
            Outcome
            <span class="text-danger">*</span>
          </template>

          <multiselect
            id="input-outcome"
            placeholder="Select Outcome Type"
            v-model="form.outcome"
            :options="['POSITIVE', 'NEGATIVE', 'NEUTRAL']"
          ></multiselect>

          <b-form-invalid-feedback id="input-status-invalid">Please select valid outcome type.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="group-name" label-for="input-name">
          <template v-slot:label>
            Remarks
            <span class="text-danger">*</span>
          </template>

          <b-form-textarea
            id="input-name"
            type="text"
            v-model="form.remarks"
            placeholder="Enter Remark If Any"
          ></b-form-textarea>
          <b-form-text id="input-description-help">Remarks must be more than 1 characters</b-form-text>
          <b-form-invalid-feedback id="input-name-invalid">Please enter Remark .</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
          <b-row class="mt-2">
            <b-col class="text-right">
              <b-button type="submit" size="sm" variant="success" :disabled="loading">
                <span class="spinner spinner-white" v-if="loading"></span>
                <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
              </b-button>
              <b-button class="ml-2" size="sm" variant="warning" :to="{ path: `/feedback` }">
                <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
              </b-button>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import { numeric, maxLength, required, minLength } from 'vuelidate/lib/validators';
import moment from 'moment';
import { mapGetters, mapState, mapActions } from 'vuex';
import configService from '@/services/configService';
import router from '@/router';
import Multiselect from 'vue-multiselect';

export default {
  name: 'FeedbackForm',
  components: {
    Multiselect
  },
  props: {
    listUrl: String,
    formType: String,
    feedbackType: String
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  data() {
    return {
      title: '',
      formLoaded: true,
      stores: [],
      storeids: [],
      form: {
        storeids: null,
        dateValue: null,
        customerName: null,
        mobile: null,
        outcome: null,
        remarks: null
      },
      storeIdError: null,
      dateTimeFormat: configService.get('format').pickerDateTime,
      dateValue: moment().format('YYYY-MM-DD'),
      minDate: moment().format('YYYY-MM-DD')
    };
  },
  validations() {
    const formValidation = {
      mobile: {
        required,
        numeric,
        maxLength: maxLength(10),
        minLength: minLength(10)
      }
    };
    return { form: formValidation };
  },
  mounted() {
    this.listStore({ router });
  },
  computed: {
    metaDescription() {
      return this.formType === 'new' ? 'Add new Feedback' : 'Update Feedback';
    },
    ...mapGetters('alert', ['errorMessages']),
    ...mapState('feedback', ['loading', 'feedback']),
    ...mapState('user', ['storeList']),
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('feedback', ['list', 'getOne', 'postOne']),
    ...mapActions('user', ['listStore']),

    onSubmit() {
      this.storeIdError = null;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }

      const feedback = {
        storeid: this.storeids.value,
        dateOfCalling: this.dateValue,
        customerName: this.form.customerName,
        mobile: this.form.mobile,
        outcome: this.form.outcome,
        remarks: this.form.remarks
      };
      if (this.formType === 'new') {
        this.$emit('add', { feedback });
      } else {
        this.$emit('edit', { feedback });
      }
      return false;
    }
  },
  watch: {
    feedback(newValue) {
      if (!this.feedback.id) {
        return;
      }
      // Loaded feedback, assign to form
      this.dateValue = moment(newValue.dateValue).format('YYYY-MM-DD');
      this.storeids = newValue.storeids.map(store => ({ text: store, value: store }));
      this.form.customerName = newValue.customerName;
      this.form.mobile = newValue.mobile;
      this.form.outcome = newValue.outcome;
      this.form.remarks = newValue.remarks;

      this.formLoaded = true;
      this.$v.$touch(); // Set initial validation
      this.$v.$reset(); // Reset $dirty
    },
    storeList(newValue) {
      this.stores = newValue.map(store => ({ text: store.storeid, value: store.storeid }));
    }
  }
};
</script>
<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.calender {
  display: flex;
  width: auto;
  margin-left: -9px;
  margin-bottom: 0.7rem;
}
</style>
