<script src="//unpkg.com/v-viewer/dist/v-viewer.js"></script>

<template>
  <div>
    <b-row>
      <b-col>
        <h2 class="page-title text-center">{{ checklistName }}</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-right">
        <b-button
          :variant="isDarkMode ? 'outline-light' : 'secondary'"
          class="box-shadow2 mx-2"
          @click="findCheckpointChange()"
          size="sm"
          :disabled="false"
        >
          <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
        </b-button>
        <b-button
          class="box-shadow2"
          @click="submitChecklist"
          size="sm"
          :disabled="isDisabled"
          :variant="isDarkMode ? 'outline-success' : 'success'"
        >
          <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Submit
        </b-button>
        <b-button
          class="box-shadow2 ml-2"
          size="sm"
          :variant="isDarkMode ? 'outline-warning' : 'warning'"
          :to="{ path: `/checklist-fill` }"
        >
          <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
        </b-button>
      </b-col>
    </b-row>
    <b-button
      v-if="showUpload"
      size="sm"
      :variant="isDarkMode ? 'outline-success' : 'success'"
      class="box-shadow2 mr-2 mb-2"
      @click="clickUpload"
    >
      <font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />{{ uploadText }}
    </b-button>
    <b-overlay v-if="loading" show :variant="isDarkMode ? 'outline-light' : 'outline-secondary'">
      <div :class="{ 'table-row table-row-empty': !isDarkMode, 'table-row-dark table-row-empty': isDarkMode }">
        <div
          :class="{
            'table-column table-column-full text-center': !isDarkMode,
            'table-column-dark table-column-full text-center': isDarkMode
          }"
        >
        </div>
      </div>
    </b-overlay>

    <div class="box-shadow2 border-top">
      <div
        :class="{
          'table-row table-row-header': !isDarkMode,
          'table-row-dark dark-div': isDarkMode
        }"
      >
        <div
          v-for="(column, index) in columns"
          :key="column.id"
          :class="{
            ...column.class,
            'table-column': !isDarkMode,
            'table-column-dark': isDarkMode,
            'table-column-first': index === 0,
            'table-column-last-odd': columns.length === index + 1 && (columns.length - 1) % 2 === 1
          }"
          :style="columnStyle(column)"
        >
          {{ column.headerText }}
        </div>
      </div>
      <div
        :class="{
          'table-row ': !isDarkMode,
          'table-row-dark': isDarkMode
        }"
        v-for="row in allCheckpoints"
        :key="row.id"
      >
        <div
          v-for="(column, index) in columns"
          :key="column.id"
          :class="{
            ...column.class,
            'table-column ': !isDarkMode,
            'table-column-dark': isDarkMode,
            'table-column-first': index === 0,
            'table-column-last-odd': columns.length === index + 1 && (columns.length - 1) % 2 === 1
          }"
          :style="columnStyle(column)"
        >
          <slot v-if="column.slotKey" :name="column.slotKey" v-bind:row="row"></slot>
          <div v-if="column.htmlKey" v-html="row[column.htmlKey]"></div>
          <div v-if="column.urlKey"
            ><a :href="row[column.urlKey]" target="_blank">{{ row[column.urlKey] }}</a></div
          >

          <span
            v-if="
              column.textKey &&
              column.textKey != 'checkpointType' &&
              column.textKey != 'picture' &&
              column.textKey != 'isImageMandatory'
            "
            class="span-text"
            >{{ row[column.textKey] }}</span
          >

          <div v-viewer v-if="column.type === 'image' && typeof row[column.textKey] != 'string' && row[column.textKey]">
            <div>
              <template>
                <div v-for="image in row[column.textKey]" :key="image" class="imageview">
                  <img :src="image" class="image" />
                </div>
              </template>
            </div>
          </div>

          <div v-if="column.textKey === 'isImageMandatory'">
            {{ row[column.textKey] ? 'Yes' : 'No' }}
          </div>

          <link href="//unpkg.com/viewerjs/dist/viewer.css" rel="stylesheet" />
          <div v-if="column.textKey === 'checkpointType' && row[column.textKey] === 'BOOLEAN'">
            <b-col>
              <multiselect
                id="input-critical"
                placeholder="Select"
                v-model="row.booleanAnswer"
                :options="viewType"
                :class="{
                  'box-shadow3': !isDarkMode,
                  darkMode: isDarkMode
                }"
              ></multiselect>
              <b-form-textarea
                :class="{ '': !isDarkMode, 'bg-dark text-light placeholder-light': isDarkMode }"
                id="input-2"
                rows="3"
                v-model="row.remarks"
                required
                placeholder="Remarks"
              ></b-form-textarea>
            </b-col>
          </div>
          <div v-if="column.textKey === 'checkpointType' && row[column.textKey] === 'TARGET'">
            <b-form-input id="input-2" v-model="row.target" required placeholder="Target"></b-form-input>
            <b-form-input id="input-2" v-model="row.actual" required placeholder="Actual"></b-form-input>
            <b-form-textarea
              id="input-2"
              rows="3"
              v-model="row.remarks"
              required
              placeholder="Remarks"
            ></b-form-textarea>
          </div>

          <div v-if="column.type === 'functions'" class="text-center">
            <b-button-group vertical>
              <b-button
                data-v-step="5"
                v-if="column.functions.edit"
                size="sm"
                :class="{
                  '': !isDarkMode,
                  'text-white': isDarkMode
                }"
                variant="outline-secondary"
                @click.once="clickSave(row)"
              >
                <font-awesome-icon :icon="['fas', 'edit']" class="mr-1" />Save
              </b-button>
            </b-button-group>
          </div>
        </div>
      </div>
      <div
        v-if="!loading && allCheckpoints.length === 0"
        :class="{ 'table-row table-row-empty': !isDarkMode, 'table-row-dark table-row-empty': isDarkMode }"
      >
        <div
          :class="{
            'table-column table-column-full text-center': !isDarkMode,
            'table-column-dark table-column-full text-center': isDarkMode
          }"
          >{{ emptyText }}</div
        >
      </div>
      <div
        v-if="loading"
        :class="{ 'table-row table-row-empty': !isDarkMode, 'table-row-dark table-row-empty': isDarkMode }"
      >
        <div
          :class="{
            'table-column table-column-full text-center': !isDarkMode,
            'table-column-dark table-column-full text-center': isDarkMode
          }"
        >
          <span
            :class="{
              'spinner spinner-black': !isDarkMode,
              'spinner spinner-white': isDarkMode
            }"
          ></span>
        </div>
      </div>
    </div>
    <b-row class="mt-4">
      <b-col class="text-right">
        <b-button
          :variant="isDarkMode ? 'outline-light' : 'secondary'"
          class="box-shadow2 mx-2"
          @click="findCheckpointChange()"
          size="sm"
          :disabled="false"
        >
          <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
        </b-button>
        <b-button
          @click="submitChecklist"
          class="box-shadow2"
          size="sm"
          :disabled="isDisabled"
          :variant="isDarkMode ? 'outline-success' : 'success'"
        >
          <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Submit
        </b-button>
        <b-button
          class="box-shadow2 ml-2"
          size="sm"
          :variant="isDarkMode ? 'outline-warning' : 'warning'"
          :to="{ path: `/checklist-fill` }"
        >
          <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import VueViewer from 'v-viewer';
import Vue from 'vue';
import router from '@/router';

Vue.use(VueViewer);

export default {
  name: 'checklistFillBox',
  data() {
    return {
      viewType: ['YES', 'NO'],
      checkpoints: [],
      isCritical: false,
      allCheckpoints: [],
      storeObject: '',
      checklistId: '',
      reportId: '',
      isDisabled: true,
      validatingImageMandatory: false
    };
  },
  components: {
    Multiselect
  },
  async mounted() {
    this.getStoreByUser();
    if (this.format === 'report') {
      this.reportId = this.ID;
    }
  },
  props: {
    columns: Array,
    rows: Array,
    format: String,
    ID: String,
    emptyText: String,
    checklistName: String,
    onlySavedReport: Boolean,
    showUpload: Boolean,
    uploadText: String
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Add New Checklist' : 'Update Checklist';
    },
    ...mapState('checklist', ['loading', 'fillingCheckpoints']),
    ...mapState('cstSubmission', ['store']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('checklistFill', [
      'loading',
      'availableChecklists',
      'userFilledChecklists',
      'storeSelected',
      'report',
      'checkpointResponse'
    ]),
    serverResponse() {
      return this.checkpointResponse;
    }
  },
  watch: {
    onlySavedReport(newValue) {
      this.isDisabled = false;
    },
    checkpointResponse(newValue) {
      if (this.allCheckpoints.every(item => item.booleanAnswer !== '' || item.target !== '') && newValue.length) {
        this.isDisabled = false;
      }
    },
    report(newValue) {
      this.reportId = newValue._id;
    },
    rows(newValue) {
      if (newValue !== undefined && newValue.length > 0) {
        if (this.format === 'report') {
          this.checklistId = newValue[0]?.checkpoint?.checklist;
        } else {
          if (newValue[0]?.checkpoint?.checklist) {
            this.checklistId = newValue[0]?.checkpoint?.checklist;
          } else {
            this.checklistId = newValue[0]?.checklist;
          }
        }
        const validation = newValue.filter(item => item?.isImageMandatory === true && item?.picture?.length === 0);
        if (validation.length > 0) {
          this.validatingImageMandatory = true;
        }
        this.allCheckpoints = newValue.map((item, index) => ({
          ...item,
          rowNum: index + 1
        }));
      }
    },

    store(newValue) {
      this.storeObject = newValue.filter(store => store.storeid === this.storeSelected)[0]?._id;
    }
  },
  methods: {
    ...mapActions('cstSubmission', ['getStoreByUser']),
    ...mapActions('checklistFill', ['generateReport', 'singleCheckpoint', 'patchOne']),
    columnStyle(column) {
      return { width: column.width };
    },
    clickUpload() {
      this.$emit('show-upload', {});
    },
    checkSaveDisabled() {
      if (this.format === 'report') {
        return true;
      } else if (this.reportId != '') {
        return true;
      } else {
        return false;
      }
    },

    findCheckpointChange() {
      const changedCheckpoints = this.allCheckpoints.filter(item => item.booleanAnswer !== '' || item.target !== '');

      const isTargetGreater = changedCheckpoints
        .filter(item => item.checkpointType === 'TARGET')
        .every(item => {
          if (parseInt(item.target) >= parseInt(item.actual)) {
            return true;
          } else return false;
        });

      if (isTargetGreater) {
        const scoreAndProgress = this.calculateChecklistScoreAndProgress({
          submissions: this.allCheckpoints.map(item => {
            return { ...item, score: this.calculateScore(item) };
          }),
          checkpointsCount: this.allCheckpoints.length
        });
        this.generateReport({
          reportID: this.reportId,
          params: {
            completed: false,
            checklist: this.checklistId,
            store: this.storeObject,
            location: {
              lat: null,
              long: null
            }
          },
          singleCheckpoint: {
            checkpoints: changedCheckpoints.map(row => {
              return {
                isCritical: row.isCritical,
                target: row.target,
                actual: row.actual,
                remarks: row.remarks ? row.remarks : '',
                booleanAnswer: row.booleanAnswer ? row.booleanAnswer : null,
                checkpoint: this.calculateCheckpointID(row),
                questionText: row.questionText,
                subCategory: row.subCategory,
                category: row.category,
                picture: row.picture ? row.picture : [],
                score: this.calculateScore(row),
                weightage: row.weightage,
                checkpointType: row.checkpointType,
                createdAt: moment().format(),
                isImageMandatory: row.isImageMandatory
              };
            }),
            overallScore: scoreAndProgress.overallScore,
            overallProgress: scoreAndProgress.overallProgress
          },
          router,
          redirectUrl: '/checklist-fill'
        });
      } else {
        Vue.swal({
          title: 'Access Denied',
          text: `TARGET must be greater or equal to ACTUAL`,
          type: 'error',
          confirmButtonText: 'Ok',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        });
        return null;
      }
    },
    async submitChecklist() {
      if (this.validatingImageMandatory) {
        Vue.swal({
          title: 'Access Denied',
          text: `Please upload the mandatory images from App before Submission`,
          type: 'error',
          confirmButtonText: 'Ok',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        });
        return null;
      } else if (this.storeObject === '' || this.storeObject === undefined) {
        Vue.swal({
          title: 'Access Denied',
          text: `Store Id must be selected`,
          type: 'error',
          confirmButtonText: 'Ok',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        });
        return null;
      } else {
        await Vue.swal({
          title: 'Add email to share (if any)',
          text: '** Add emails by separating them with commas **',
          showCancelButton: true,
          input: 'text',
          inputPlaceholder: 'Enter Email',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Submit',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        }).then(result => {
          if (result.isConfirmed) {
            let emailArray = [];

            if (!result.value) {
              emailArray = [];
            } else {
              emailArray = result.value?.split(',').map(item => {
                let position = item.search('@');
                if (position >= 0) {
                  const abc = item.trim().slice(0, position);
                  return `${abc}@rpsg.in`;
                } else {
                  return `${item.trim()}@rpsg.in`;
                }
              });
            }

            this.patchOne({
              params: {
                completed: true,
                checklist: this.checklistId,
                acknowledgedBy: null,
                store: this.storeObject,
                reportId: this.reportId,
                sendEmailTo: emailArray,
                location: {
                  lat: null,
                  long: null
                }
              },
              router,
              redirectUrl: '/checklist-fill'
            });
          }
        });
      }
    },
    calculateChecklistScoreAndProgress({ submissions, checkpointsCount }) {
      let score = 0,
        progress = 0,
        totalWeightage = 0;
      submissions.forEach(item => {
        if (item.score > -1) {
          score += item.score;
          totalWeightage += item.weightage || item.checkpoint.weightage;
          progress++;
        }
      });

      const overallScore = progress > 0 ? Math.round((score * 100) / totalWeightage) / 100 : 0;
      const overallProgress = Math.round((progress * 100) / checkpointsCount) / 100;

      return {
        overallScore,
        overallProgress
      };
    },
    calculateScore(row) {
      const type = row.checkpointType;
      if (type === 'BOOLEAN') {
        if (row.booleanAnswer === 'YES') {
          return row.weightage;
        } else if (row.booleanAnswer === 'NO') {
          return 0;
        } else {
          return -1;
        }
      } else {
        let score = -1;
        score = parseInt(row.actual) / parseInt(row.target);
        return score;
      }
    },
    calculateCheckpointID(row) {
      if (row.checkpoint) {
        return row.checkpoint._id;
      } else {
        return row._id;
      }
    },
    clickSave(row) {
      const scoreAndProgress = this.calculateChecklistScoreAndProgress({
        submissions: this.allCheckpoints.map(item => {
          return { ...item, score: this.calculateScore(item) };
        }),
        checkpointsCount: this.allCheckpoints.length
      });
      this.generateReport({
        reportID: this.reportId,
        params: {
          completed: false,
          checklist: this.checklistId,
          store: this.storeObject,
          location: {
            lat: null,
            long: null
          }
        },
        singleCheckpoint: {
          checkpoints: [
            {
              isCritical: row.isCritical,
              target: row.target,
              actual: row.actual,
              remarks: row.remarks ? row.remarks : '',
              booleanAnswer: row.booleanAnswer ? row.booleanAnswer : null,
              checkpoint: this.calculateCheckpointID(row),
              questionText: row.questionText,
              subCategory: row.subCategory,
              category: row.category,
              picture: [],
              score: this.calculateScore(row),
              weightage: row.weightage,
              checkpointType: row.checkpointType,
              createdAt: moment().format(),
              isImageMandatory: row.isImageMandatory
            }
          ],
          overallScore: scoreAndProgress.overallScore,
          overallProgress: scoreAndProgress.overallProgress
        }
      });
    }
  }
};
</script>

<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
input {
  width: 100%;
  margin: 1px;
}

.imageview {
  display: inline-flex;
  margin: 2px;
}

img {
  width: 3em !important;
  height: 4.5em !important;
  transition: transform 0.2s;
}
</style>
