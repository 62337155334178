var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-tablebox"},[(_vm.showToolbar)?_c('b-row',{staticClass:"table-top-wrapper mb-2 mx-0"},[_c('b-col',{staticClass:"px-0",attrs:{"sm":""}},[_c('b-form',{attrs:{"inline":""},on:{"submit":function($event){$event.preventDefault();}}},[_c('b-input-group',{staticClass:"box-shadow2 ml-2 mb-2",attrs:{"data-v-step":"0"},scopedSlots:_vm._u([(_vm.searchItems)?{key:"append",fn:function(){return [_c('b-dropdown',{attrs:{"menu-class":_vm.isDarkMode ? 'bg-dark box-shadow2' : 'box-shadow2',"text":_vm.selectedSearchVariable,"variant":_vm.isDarkMode ? 'primary' : 'success'}},_vm._l((_vm.searchItems),function(item){return _c('b-dropdown-item',{key:item,attrs:{"link-class":_vm.isDarkMode ? 'text-light bg-dark' : ''},on:{"click":function($event){_vm.selectedSearchVariable = item}}},[_vm._v(_vm._s(item))])}),1)]},proxy:true}:null],null,true)},[_c('b-form-input',{class:{
              'bg-light text-dark': !_vm.isDarkMode,
              'bg-dark text-light placeholder-light': _vm.isDarkMode
            },attrs:{"placeholder":"Search..."},nativeOn:{"keydown":function($event){return _vm.search.apply(null, arguments)}},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),(_vm.showFilter)?_c('b-form-select',{class:{
            'box-shadow2 ml-2 mb-2  bg-light text-dark': !_vm.isDarkMode,
            'box-shadow2 ml-2 mb-2 bg-dark text-light': _vm.isDarkMode
          },attrs:{"data-v-step":"1","options":_vm.filterOptions},model:{value:(_vm.filterSelected),callback:function ($$v) {_vm.filterSelected=$$v},expression:"filterSelected"}}):_vm._e()],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"sm":""}},[_c('b-form',{staticClass:"float-right",attrs:{"data-v-step":"2","inline":""}},[_c('b-input-group',{staticClass:"box-shadow2 ml-2 mb-2"},[_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"button-only":"","id":"start-datepicker","placeholder":"Start Date","min":_vm.minDate,"max":_vm.maxDate,"date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' }},model:{value:(_vm.startDateValue),callback:function ($$v) {_vm.startDateValue=$$v},expression:"startDateValue"}})],1),_c('b-form-input',{class:{
              '': !_vm.isDarkMode,
              'bg-dark text-light placeholder-light': _vm.isDarkMode
            },attrs:{"id":"example-input","type":"text","placeholder":"Start Date","autocomplete":"off","disabled":""},model:{value:(_vm.startDateValue),callback:function ($$v) {_vm.startDateValue=$$v},expression:"startDateValue"}})],1),_c('b-input-group',{staticClass:"box-shadow2 ml-2 mb-2"},[_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"button-only":"","id":"end-datepicker","placeholder":"End Date","min":_vm.minDate,"max":_vm.maxDate,"date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' }},model:{value:(_vm.endDateValue),callback:function ($$v) {_vm.endDateValue=$$v},expression:"endDateValue"}})],1),_c('b-form-input',{class:{
              '': !_vm.isDarkMode,
              'bg-dark text-light placeholder-light': _vm.isDarkMode
            },attrs:{"id":"example-input","type":"text","placeholder":"End Date","autocomplete":"off","disabled":""},model:{value:(_vm.endDateValue),callback:function ($$v) {_vm.endDateValue=$$v},expression:"endDateValue"}})],1),_c('b-button',{class:{
            'box-shadow2 btn btn-success ml-2 mb-2': !_vm.isDarkMode,
            'btn btn-primary text-light ml-2 mb-2': _vm.isDarkMode
          },on:{"click":_vm.applyDate}},[_vm._v("Apply")]),_c('b-button',{staticClass:"box-shadow2 ml-2 mb-2",attrs:{"variant":_vm.isDarkMode ? 'outline-light' : 'outline-secondary'},on:{"click":_vm.clearDate}},[_vm._v("Clear")])],1)],1)],1):_vm._e(),_c('b-row',{staticClass:"table-bottom-wrapper mb-2"},[_c('b-col',{attrs:{"sm":"12","lg":"6"}},[(_vm.showAdd)?_c('b-button',{class:{
          'box-shadow2 btn btn-success mb-2 mr-2': !_vm.isDarkMode,
          'btn btn-primary text-light mb-2 mr-2': _vm.isDarkMode
        },attrs:{"data-v-step":"3","size":"sm"},on:{"click":_vm.clickAdd}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'plus']}}),_vm._v(_vm._s(_vm.addText)+" ")],1):_vm._e(),(_vm.showDownloadDashboard)?_c('b-button',{class:{
          'box-shadow2 btn btn-success mb-2 mr-2': !_vm.isDarkMode,
          'btn btn-primary text-light mb-2 mr-2': _vm.isDarkMode
        },attrs:{"data-v-step":"4","size":"sm"},on:{"click":_vm.clickDownloadDashboard}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'download']}}),_vm._v(_vm._s(_vm.downloadDashboardText)+" ")],1):_vm._e(),(_vm.showDownload)?_c('b-button',{class:{
          'box-shadow2 btn btn-success mb-2 mr-2': !_vm.isDarkMode,
          'btn btn-primary text-light mb-2 mr-2': _vm.isDarkMode
        },attrs:{"data-v-step":"4","size":"sm"},on:{"click":_vm.clickDownload}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'download']}}),_vm._v(_vm._s(_vm.downloadText)+" ")],1):_vm._e(),(_vm.showDownloadReport)?_c('b-button',{class:{
          'box-shadow2 btn btn-success mb-2 mr-2': !_vm.isDarkMode,
          'btn btn-primary text-light mb-2 mr-2': _vm.isDarkMode
        },attrs:{"data-v-step":"4","size":"sm"},on:{"click":_vm.clickDownloadActiveReport}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'download']}}),_vm._v(_vm._s(_vm.downloadReportText)+" ")],1):_vm._e(),(_vm.showDownloadKeyPoint)?_c('b-button',{class:{
          'box-shadow2 btn btn-success mb-2 mr-2': !_vm.isDarkMode,
          'btn btn-primary text-light mb-2 mr-2': _vm.isDarkMode
        },attrs:{"data-v-step":"4","size":"sm"},on:{"click":_vm.clickDownloadKeyPoint}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'download']}}),_vm._v(_vm._s(_vm.downloadKeyPointText)+" ")],1):_vm._e(),(_vm.showRolesReport)?_c('b-button',{class:{
          'box-shadow2 btn btn-success mb-2 mr-2': !_vm.isDarkMode,
          'btn btn-primary text-light mb-2 mr-2': _vm.isDarkMode
        },attrs:{"data-v-step":"4","size":"sm"},on:{"click":_vm.clickDownloadRolesReport}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'download']}}),_vm._v(_vm._s(_vm.downloadRolesReportText)+" ")],1):_vm._e(),(_vm.showUpload)?_c('b-button',{class:{
          'box-shadow2 btn btn-success mb-2 mr-2': !_vm.isDarkMode,
          'btn btn-primary text-light mb-2 mr-2': _vm.isDarkMode
        },attrs:{"data-v-step":"5","size":"sm"},on:{"click":_vm.clickUpload}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'upload']}}),_vm._v(_vm._s(_vm.uploadText)+" ")],1):_vm._e(),(_vm.showDownloadMessageReport)?_c('b-button',{class:{
          'box-shadow2 btn btn-success mb-2 mr-2': !_vm.isDarkMode,
          'btn btn-primary text-light mb-2 mr-2': _vm.isDarkMode
        },attrs:{"size":"sm"},on:{"click":_vm.clickDownloadReport}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'download']}}),_vm._v(_vm._s(_vm.downloadMessageReportText)+" ")],1):_vm._e(),(_vm.showDownloadMis)?_c('b-button',{class:{
          'box-shadow2 btn btn-success mr-2': !_vm.isDarkMode,
          'btn btn-primary text-light mr-2': _vm.isDarkMode
        },attrs:{"size":"sm"},on:{"click":_vm.clickDownloadMis}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'download']}}),_vm._v(_vm._s(_vm.downloadMisText)+" ")],1):_vm._e()],1)],1),_c('div',{staticClass:"table-top-wrapper"},[(_vm.totalCount)?_c('div',{staticClass:"row-total-count"},[_c('span',[_vm._v("Total Count:")]),_vm._v(" "+_vm._s(_vm.totalCount)+" ")]):_vm._e()]),_c('div',{class:{
      'box-shadow2': !_vm.isDarkMode,
      '': _vm.isDarkMode
    }},[_c('div',{class:{
        'table-row table-row-header': !_vm.isDarkMode,
        'table-row-dark dark-div border-top': _vm.isDarkMode
      },attrs:{"data-v-step":"10"}},_vm._l((_vm.columns),function(column,index){return _c('div',{key:column.id,staticClass:"table-column",class:Object.assign({}, column.class,
          {'table-column': !_vm.isDarkMode,
          'table-column-dark': _vm.isDarkMode,
          'table-column-first': index === 0,
          'table-column-last-odd': _vm.columns.length === index + 1 && (_vm.columns.length - 1) % 2 === 1}),style:(_vm.columnStyle(column))},[_vm._v(" "+_vm._s(column.headerText)+" "),(_vm.handleShowHeading(column.textKey))?_c('b-button',{staticClass:"text-decoration-none btn-sm",attrs:{"variant":_vm.isDarkMode ? 'dark' : 'link'},on:{"click":function($event){return _vm.handleHeadingShorting(column.textKey)}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'sort']}})],1):_vm._e()],1)}),0),_vm._l((_vm.updatedRows),function(row){return _c('div',{key:row.id,class:{
        'table-row ': !_vm.isDarkMode,
        'table-row-dark': _vm.isDarkMode
      }},_vm._l((_vm.columns),function(column,index){return _c('div',{key:column.textKey,staticClass:"table-column",class:Object.assign({}, column.class,
          {'table-column ': !_vm.isDarkMode,
          'table-column-dark': _vm.isDarkMode,
          'table-column-first': index === 0,
          'table-column-last-odd': _vm.columns.length === index + 1 && (_vm.columns.length - 1) % 2 === 1}),style:(_vm.columnStyle(column))},[(column.slotKey)?_vm._t(column.slotKey,null,{"row":row}):_vm._e(),(column.htmlKey)?_c('div',{domProps:{"innerHTML":_vm._s(row[column.htmlKey])}}):_vm._e(),(column.urlKey)?_c('div',[_c('a',{attrs:{"href":row[column.urlKey],"target":"_blank"}},[_vm._v(_vm._s(row[column.urlKey]))])]):_vm._e(),(column.textKey && column.textKey !== 'reportStatusColor')?_c('span',{staticClass:"span-text"},[_vm._v(_vm._s(row[column.textKey]))]):_vm._e(),(column.textKey === 'reportStatusColor' && row[column.textKey] !== null)?_c('div',[_c('b-button',{staticClass:"box-shadow2",style:({ backgroundColor: row[column.textKey], color: 'black', border: 'none' })},[_vm._v(_vm._s(_vm.handleColor(row[column.textKey])))])],1):_vm._e(),(column.type === 'functions')?_c('div',{staticClass:"text-center"},[_c('b-button-group',{staticClass:"box-shadow2",attrs:{"size":"sm"}},[(column.functions.ack)?_c('b-button',{staticClass:"mb-1",attrs:{"size":"sm","variant":_vm.isDarkMode ? 'outline-success' : 'success'},on:{"click":function($event){return _vm.clickAcknowledge(row)}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'sms']}}),_vm._v("Acknowledge ")],1):_vm._e()],1),_c('b-button-group',{staticClass:"box-shadow2",attrs:{"size":"sm","vertical":""}},[(column.functions.addOrder)?_c('b-button',{class:{
                '': !_vm.isDarkMode,
                'text-white': _vm.isDarkMode
              },attrs:{"size":"sm","variant":_vm.isDarkMode ? 'outline-primary' : 'primary'},on:{"click":function($event){return _vm.clickAddOrder(row)}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'plus']}}),_vm._v("Add Order ")],1):_vm._e(),(column.functions.open && row.hasExpired)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"data-v-step":"6","title":"Checklist Has been expired","size":"sm","variant":_vm.isDarkMode ? 'outline-light' : 'secondary'}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'edit']}}),_vm._v("Expired ")],1):_vm._e(),(column.functions.open && (row.hasExpired ? !row.hasExpired : true))?_c('b-button',{attrs:{"data-v-step":"6","size":"sm","variant":_vm.isDarkMode ? 'outline-light' : 'secondary'},on:{"click":function($event){return _vm.clickOpen(row)}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'edit']}}),_vm._v("Open ")],1):_vm._e(),(column.functions.submit)?_c('b-button',{class:{
                '': !_vm.isDarkMode,
                'text-white': _vm.isDarkMode
              },attrs:{"data-v-step":"6","size":"sm","variant":_vm.isDarkMode ? 'outline-success' : 'success'},on:{"click":function($event){return _vm.clickSubmit(row)}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'plus']}}),_vm._v("Submit ")],1):_vm._e(),(column.functions.edit)?_c('b-button',{attrs:{"data-v-step":"7","size":"sm","variant":_vm.isDarkMode ? 'outline-light' : 'secondary'},on:{"click":function($event){return _vm.clickEdit(row)}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'edit']}}),_vm._v("View/Edit ")],1):_vm._e(),(column.functions.audit)?_c('b-button',{class:{
                '': !_vm.isDarkMode,
                'text-white': _vm.isDarkMode
              },attrs:{"data-v-step":"6","size":"sm","variant":_vm.isDarkMode ? 'outline-primary' : 'outline-primary'},on:{"click":function($event){return _vm.clickDownloadAudit(row)}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'chart-pie']}}),_vm._v("Download Report ")],1):_vm._e(),(column.functions.delete)?_c('b-button',{class:{
                '': !_vm.isDarkMode,
                'text-white': _vm.isDarkMode
              },attrs:{"size":"sm","variant":_vm.isDarkMode ? 'outline-warning' : 'warning'},on:{"click":function($event){return _vm.clickDelete(row)}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'trash-alt']}}),_vm._v(_vm._s(_vm.deleteText ? _vm.deleteText : 'Delete')+" ")],1):_vm._e()],1)],1):_vm._e()],2)}),0)}),(!_vm.loading && _vm.rows.length === 0)?_c('div',{class:{ 'table-row table-row-empty': !_vm.isDarkMode, 'table-row-dark table-row-empty': _vm.isDarkMode }},[_c('div',{class:{
          'table-column table-column-full text-center': !_vm.isDarkMode,
          'table-column-dark table-column-full text-center': _vm.isDarkMode
        }},[_vm._v(_vm._s(_vm.emptyText))])]):_vm._e(),(_vm.loading)?_c('b-overlay',{attrs:{"show":"","variant":_vm.isDarkMode ? 'outline-light' : 'outline-secondary'}},[_c('div',{class:{ 'table-row table-row-empty': !_vm.isDarkMode, 'table-row-dark table-row-empty': _vm.isDarkMode }},[_c('div',{class:{
            'table-column table-column-full text-center': !_vm.isDarkMode,
            'table-column-dark table-column-full text-center': _vm.isDarkMode
          }})])]):_vm._e()],2),_c('b-row',{staticClass:"table-bottom-wrapper mt-2 mx-0"},[_c('b-col',{staticClass:"px-0",attrs:{"cols":8}},[(_vm.showAdd)?_c('b-button',{class:{
          'box-shadow2 btn btn-success mb-2 mr-2': !_vm.isDarkMode,
          'btn btn-primary text-light mb-2 mr-2': _vm.isDarkMode
        },attrs:{"size":"sm"},on:{"click":_vm.clickAdd}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'plus']}}),_vm._v(_vm._s(_vm.addText)+" ")],1):_vm._e(),(_vm.showUpload)?_c('b-button',{class:{
          'box-shadow2 btn btn-success mb-2 mr-2': !_vm.isDarkMode,
          'btn btn-primary text-light mb-2 mr-2': _vm.isDarkMode
        },attrs:{"size":"sm"},on:{"click":_vm.clickUpload}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'upload']}}),_vm._v(_vm._s(_vm.uploadText)+" ")],1):_vm._e(),(_vm.showDownloadMessageReport)?_c('b-button',{staticClass:"mr-2 mb-2",attrs:{"size":"sm","variant":"success"},on:{"click":_vm.clickDownloadReport}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'download']}}),_vm._v(_vm._s(_vm.downloadMessageReportText)+" ")],1):_vm._e()],1),(_vm.showPagination)?_c('b-col',{attrs:{"lg":"4","sm":"12"}},[(_vm.rows.length)?_c('b-pagination-nav',{class:{ '': !_vm.isDarkMode, 'dark-nav': _vm.isDarkMode },attrs:{"id":"page-nav","data-v-step":"9","link-gen":_vm.linkGen,"number-of-pages":_vm.totalNumberOfPage,"use-router":"","no-page-detect":"","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }