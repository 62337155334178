<template>
  <div>
    <h1 class="page-title text-center">{{ title }}</h1>
    <b-row class="table-top-wrapper mb-2 mx-0 mt-5">
      <b-col sm class="px-0">
        <b-form inline>
          <!-- <div data-v-step="0">
            <b-form-select v-model="regionSelected" :options="regions" class="ml-2 w-35 mb-2"></b-form-select>
            <b-form-select v-model="clusterSelected" :options="clusters" class="ml-2 w-35 mb-2"></b-form-select>
            <b-form-select v-model="storeSelected" :options="stores" class="ml-2 w-35 mb-2"></b-form-select>
          </div> -->

          <b-col sm class="px-0">
            <b-form inline>
              <b-form-group id="group-name" label-for="input-name" class="ml-2 w-35 mb-2">
                <template v-slot:label> Start Date :- </template>
              </b-form-group>

              <b-form-datepicker
                data-v-step="1"
                id="start-datepicker"
                placeholder="Start Date"
                :min="minDate"
                :max="maxDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                v-model="startDateValue"
                class="ml-2 mb-2"
              ></b-form-datepicker>
              <b-form-group id="group-name" label-for="input-name" class="ml-2 w-35 mb-2">
                <template v-slot:label> End Date :- </template>
              </b-form-group>
              <b-form-datepicker
                data-v-step="2"
                id="end-datepicker"
                placeholder="End Date"
                :min="minDate"
                :max="maxDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                v-model="endDateValue"
                class="ml-2 mb-2"
              ></b-form-datepicker>
              <b-button data-v-step="4" variant="success" class="ml-2 mb-2" @click="downloadMIS">Download MIS</b-button>
            </b-form>
          </b-col>
        </b-form>
      </b-col>
    </b-row>
    <!-- <v-tour name="myTour" :steps="steps" :callbacks="callbacks"></v-tour> -->
    <br />
    <br />
    <!-- <div>
      <h3 class="dynamic-header">Dynamic Form Reports</h3>
      <br />
      <dynamic-form-reports
        :showReportDownload="true"
        :showFNV="true"
        downloadReportText="Oubtbound Calling Report"
        downloadFNVText="Fresh (F&V and F&M) Morning Readiness Report"
        @downloadreport="onDownloadReport"
        @downloadfnv="onDownloadFnvData"
      ></dynamic-form-reports>
    </div> -->
  </div>
</template>

<script>
import moment from 'moment';
import router from '@/router';
import { mapState, mapActions } from 'vuex';
import DynamicFormReports from '../../components/DynamicFormReports.vue';

export default {
  name: 'MIS',
  metaInfo() {
    return {
      title: 'MIS',
      meta: [
        {
          name: 'mis',
          content: `Download mis`
        }
      ]
    };
  },
  components: { DynamicFormReports },
  data() {
    return {
      title: 'MIS ',
      callbacks: {
        onFinish() {
          window.localStorage.setItem('hasCompletedMIS', 'true');
        }
      },
      steps: [
        {
          target: '[data-v-step="0"]', // We're using document.querySelector() under the hood
          content: `Select the Store according to the Region and Cluster.`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="1"]',
          content: 'Select the start date.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="2"]',
          content: 'Select the end date.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="3"]', // We're using document.querySelector() under the hood
          content: `Select the view type.`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="4"]', // We're using document.querySelector() under the hood
          content: `Download the MIS.`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        }
      ],
      isChartVisible: false,
      regions: [{ text: 'All Regions', value: 'all' }],
      clusters: [{ text: 'All Clusters', value: 'all' }],
      stores: [{ text: 'All Stores', value: 'all' }],
      viewType: [
        { text: 'Region Wise', value: 'region' },
        { text: 'Cluster Wise', value: 'cluster' },
        { text: 'Store Wise', value: 'store' }
      ],
      regionSelected: 'all',
      clusterSelected: 'all',
      storeSelected: 'all',
      checklistSelected: 'None',
      viewSelected: 'region',
      startDateValue: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      endDateValue: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      minDate: moment('2020-01-01').format('YYYY-MM-DD'),
      maxDate: moment().subtract(1, 'days').format('YYYY-MM-DD')
    };
  },
  mounted() {
    // if (window.localStorage.getItem('hasCompletedMIS') !== 'true') {
    //   this.$tours.myTour.start();
    // }
    this.listStore({ router });
    this.listCluster({ router, query: { type: 'cluster' } });
    this.listRegion({ router, query: { type: 'region' } });
  },
  methods: {
    ...mapActions('mis', ['downloadMis', 'outboundFormReport', 'FnvFormReport']),
    ...mapActions('store', ['listStore', 'listCluster', 'listRegion']),
    onDownloadReport() {
      this.outboundFormReport({
        query: {
          startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
          enddate: moment(this.endDateValue).format('YYYY-MM-DD')
        }
      });
    },
    onDownloadFnvData() {
      this.FnvFormReport({
        query: {
          startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
          enddate: moment(this.endDateValue).format('YYYY-MM-DD')
        }
      });
    },
    downloadMIS({ download = true }) {
      let storesList = [];
      if (this.storeSelected === 'all') {
        storesList = this.stores.map(s => s.value);
        storesList.shift();
      } else {
        storesList = this.storeSelected;
      }
      this.downloadMis({
        query: {
          startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
          enddate: moment(this.endDateValue).format('YYYY-MM-DD'),
          stores: storesList
        },
        download
      });
    },
    calculateRegionWise(data) {
      const counts = data.reduce((p, c) => {
        const mis = p;
        if (!Object.prototype.hasOwnProperty.call(mis, c.region)) {
          mis[c.region] = { ...c };
        }
        Object.keys(c).forEach(key => {
          if (Object.prototype.hasOwnProperty.call(c, key) && !['storeid', 'region', 'cluster', 'row'].includes(key)) {
            mis[c.region][key] += c[key];
          }
        });
        return mis;
      }, {});
      return Object.values(counts);
    },
    calculateClusterWise(data) {
      const counts = data.reduce((p, c) => {
        const mis = p;
        if (!Object.prototype.hasOwnProperty.call(mis, c.cluster)) {
          mis[c.cluster] = { ...c };
        }
        Object.keys(c).forEach(key => {
          if (Object.prototype.hasOwnProperty.call(c, key) && !['storeid', 'region', 'cluster'].includes(key)) {
            mis[c.cluster][key] += c[key];
          }
        });
        return mis;
      }, {});
      return Object.values(counts);
    }
  },
  computed: {
    ...mapState('mis', ['misList', 'misChart', 'misTable', 'misCountList', 'outboundFormData', 'fnvFormData']),
    ...mapState('store', ['storeList', 'clusterList', 'regionList']),
    ...mapState('auth', ['user']),
    regionChart() {
      return this.calculateRegionWise(this.misChart);
    },
    clusterChart() {
      return this.calculateClusterWise(this.misChart);
    }
  },

  watch: {
    regionSelected(value) {
      if (value === 'all') {
        this.listStore({ router });
        this.listCluster({ router, query: { type: 'cluster' } });
      } else {
        this.listStore({ router, query: { by: 'region', value } });
        this.listCluster({ router, query: { type: 'cluster', by: 'region', value } });
      }
    },
    clusterSelected(value) {
      if (value === 'all') {
        this.listStore({ router });
      } else {
        this.listStore({ router, query: { by: 'cluster', value } });
      }
    },
    clusterList(newValue) {
      this.clusters = [{ text: 'All Clusters', value: 'all' }];
      this.clusters = [...this.clusters, ...newValue];
    },
    regionList(newValue) {
      this.regions = [{ text: 'All Regions', value: 'all' }];
      this.regions = [...this.regions, ...newValue];
    },
    storeList(newValue) {
      if (this.user.roles.filter(role => role.name === 'ADMIN')) {
        this.stores = [{ text: 'All Stores', value: 'all' }];
        this.stores = [...this.stores, ...newValue.map(store => ({ text: store.storeid, value: store.storeid }))];
      } else {
        this.stores = [{ text: 'All Stores', value: 'all' }];
        this.stores = [...this.stores, ...this.user.storeids.map(store => ({ text: store, value: store }))];
      }
    },
    misCountList(newValue) {
      if (newValue.length) {
        const keys = newValue[0].count.map(c => Object.keys(c)[0]).join(',');
        let csv = `,,MIS from ${moment(this.startDateValue).format('DD-MM-YYYY')} to ${moment(this.endDateValue).format(
          'DD-MM-YYYY'
        )}\n`;
        csv += `Store Id,${keys}\n`;
        Object.values(newValue).forEach(val => {
          csv += `${val.storeid},`;
          Object.values(val.count).forEach(countVal => {
            csv += `${Object.values(countVal)[0]},`;
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'MIS.csv';
        anchor.click();
      }
    },
    misList(newValue) {
      if (newValue.length) {
        let csv = `,,,,,MIS from ${moment(this.startDateValue).format('DD-MM-YYYY')} to ${moment(
          this.endDateValue
        ).format('DD-MM-YYYY')}\n`;
        let processedValue = newValue;
        switch (this.chartSelected) {
          case 'region': {
            csv += 'Region,';
            processedValue = this.calculateRegionWise(newValue);
            processedValue = processedValue.map(item => {
              const region = item;
              delete region.storeid;
              delete region.cluster;
              delete region.row;
              return region;
            });
            break;
          }

          case 'cluster': {
            csv += 'Region,Cluster,';
            processedValue = this.calculateClusterWise(newValue);
            processedValue = processedValue.map(item => {
              const region = item;
              delete region.storeid;
              delete region.row;
              return region;
            });
            break;
          }

          default:
            csv += 'Row Number,Store Id,Region,Cluster,';
            break;
        }
        csv +=
          'Calls Landed Total,' +
          'Calls Answered,Calls Not Answered,' +
          'Calls Busy,Calls Failed,Calls Feedback(Not Selected),' +
          'Calls Feedback(Serviced),Calls Feedback(Not Available),' +
          'Calls Feedback(Enquiry Call),Calls Feedback(Out of Service Area),' +
          'Calls Feedback(Not Reachable),Single Order,' +
          'Bulk Order,Outbound Order,IVR Order,Acknowledged,Delivered,Call Recordings,' +
          'Recordings Feedback(Not Selected),Recordings Feedback(Serviced),' +
          'Recordings Feedback(Not Available),Recordings Feedback(Enquiry Call),' +
          'Recordings Feedback(Out of Service Area),Recordings Feedback(Not Reachable),' +
          'Outbound(Call Dialed), Outbound(Call Connected),Outbound(Terminated By Staff),Outbound(Ringed),Outbound(User Not Available' +
          '\n';
        const sumRow = [];
        processedValue.forEach(row => {
          Object.values(row).forEach((val, index) => {
            csv += `"${val}"`;
            csv += ',';
            if (typeof val !== 'number' || index === 0) {
              sumRow[index] = '';
              return;
            }

            if (sumRow[index]) {
              sumRow[index] += val;
            } else {
              sumRow[index] = val;
            }
          });
          csv += '\n';
        });
        sumRow[0] = 'India Total';
        csv += `${sumRow.join(',')}\n`;

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'MIS.csv';
        anchor.click();
      }
    },
    outboundFormData(val) {
      if (val.length) {
        let csv = `,,,,,Outbound Calling Report from ${moment(this.startDateValue).format('DD-MM-YYYY')} to ${moment(
          this.endDateValue
        ).format('DD-MM-YYYY')}\n`;
        csv += ',,,,,\n';
        csv += `Region,Cluster,AM's Name,Store Id, Store Name,Store Responded,Calls Made, Customer Converted,Conversion %,\n`;
        val.forEach(row => {
          // if region undefined then don't show that row
          if (row.region === undefined) {
            return;
          }
          const percentage =
            row.convertedPercentage !== 100 ? row.convertedPercentage.toFixed(2) : row.convertedPercentage;
          csv += `"${row.region}","${row.cluster}","${row.ASMName}","${row._id}","${row.storeName}","${row.storeResponse}","${row.callMade}","${row.customerConverted}","${percentage}%"\n`;
        });

        // const filteredVal = val.filter(row => row.region !== undefined);
        // csv += `,,,,Total,${filteredVal.reduce((a, b) => a + b.storeResponse, 0)},${filteredVal.reduce(
        //   (a, b) => a + b.callMade,
        //   0
        // )},${filteredVal.reduce((a, b) => a + b.customerConverted, 0)},${
        //   filteredVal.reduce((a, b) => a + b.convertedPercentage, 0) / filteredVal.length
        // }%\n`;

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Outbound Calling Report.csv';
        anchor.click();
      }
    },
    fnvFormData(val) {
      if (val.length) {
        let csv = `,,,,Report Date ${moment().subtract(1, 'days').format('DD-MM-YYYY')}\n`;
        csv += ',,,,,\n';
        csv += `Region,ASM,No. Of Store Applicable,No. Of Store Reverted for F&V,Store Reverted Comp % ,Vehicle Not Arrived,Vehicle In, Arrived on Time (Before 8:30am),Delay in Arrive (After 8:30am),F&V Vehicle On Time Comp. %,Stores Not Reported,\n`;
        val.forEach(row => {
          const region = row.asmStores.map(i => i.region);
          const revisedRegion = [...new Set(region)];
          const ASM = row.asmName;
          const allStores = row.asmStores.map(i => i.NoOfStores);
          const revisedStores = [...new Set(allStores)];
          const vehicleNotArrived = row.asmStores.map(i => i.notArrived);
          const arrivedOnTime = row.asmStores.map(i => i.arrivedOnTime);
          const delayInArrive = row.asmStores.map(i => i.delayInArrive);

          let sumVehicleNotArrived = 0;
          for (let i = 0; i < vehicleNotArrived.length; i++) {
            sumVehicleNotArrived += vehicleNotArrived[i];
          }

          let sumArrivedOnTime = 0;
          for (let i = 0; i < arrivedOnTime.length; i++) {
            sumArrivedOnTime += arrivedOnTime[i];
          }

          let sumDelayInArrive = 0;
          for (let i = 0; i < delayInArrive.length; i++) {
            sumDelayInArrive += delayInArrive[i];
          }

          const totalRevertedStore = sumArrivedOnTime + sumDelayInArrive;
          const storeRevertedComp = (totalRevertedStore / revisedStores) * 100;
          const vehicleOnTimeComp = (sumArrivedOnTime / totalRevertedStore) * 100;
          const storesNotReported = revisedStores - totalRevertedStore;

          csv += `"${revisedRegion}","${ASM}","${revisedStores}","${totalRevertedStore}","${storeRevertedComp}%","${sumVehicleNotArrived}","${totalRevertedStore}","${sumArrivedOnTime}","${sumDelayInArrive}","${vehicleOnTimeComp}%","${storesNotReported}"\n`;
        });
        let sumOfRevisedStores = 0;
        let sumOfTotalRevertedStore = 0;
        let sumOfSumVehicleNotArrived = 0;
        let sumOfSumArrivedOnTime = 0;
        let sumOfSumDelayInArrive = 0;
        let sumOfStoresNotReported = 0;

        val.forEach(row => {
          const allStores = row.asmStores.map(i => i.NoOfStores);
          const revisedStores = [...new Set(allStores)];
          const vehicleNotArrived = row.asmStores.map(i => i.notArrived);
          const arrivedOnTime = row.asmStores.map(i => i.arrivedOnTime);
          const delayInArrive = row.asmStores.map(i => i.delayInArrive);

          let sumVehicleNotArrived = 0;
          for (let i = 0; i < vehicleNotArrived.length; i++) {
            sumVehicleNotArrived += vehicleNotArrived[i];
          }

          let sumArrivedOnTime = 0;
          for (let i = 0; i < arrivedOnTime.length; i++) {
            sumArrivedOnTime += arrivedOnTime[i];
          }

          let sumDelayInArrive = 0;
          for (let i = 0; i < delayInArrive.length; i++) {
            sumDelayInArrive += delayInArrive[i];
          }

          let sumRevisedStores = 0;
          for (let i = 0; i < revisedStores.length; i++) {
            sumRevisedStores += revisedStores[i];
          }

          const totalRevertedStore = sumArrivedOnTime + sumDelayInArrive;
          const storesNotReported = revisedStores - totalRevertedStore;

          sumOfRevisedStores += sumRevisedStores;
          sumOfTotalRevertedStore += totalRevertedStore;
          sumOfSumVehicleNotArrived += sumVehicleNotArrived;
          sumOfSumArrivedOnTime += sumArrivedOnTime;
          sumOfSumDelayInArrive += sumDelayInArrive;
          sumOfStoresNotReported += storesNotReported;
        });

        const sumOfStoreRevertedComp = (sumOfTotalRevertedStore / sumOfRevisedStores) * 100;
        const sumOfVehicleOnTimeComp = (sumOfSumArrivedOnTime / sumOfTotalRevertedStore) * 100;

        csv += `,PAN India,"${sumOfRevisedStores}","${sumOfTotalRevertedStore}","${sumOfStoreRevertedComp}%","${sumOfSumVehicleNotArrived}","${sumOfTotalRevertedStore}","${sumOfSumArrivedOnTime}","${sumOfSumDelayInArrive}","${sumOfVehicleOnTimeComp}%","${sumOfStoresNotReported}"\n`;

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'FNV Report.csv';
        anchor.click();
      }
    }
  }
};
</script>

<style scoped>
.dynamic-header {
  border-top: 1px solid #d9d9d9;
}
</style>
