<template>
  <div class="page-class">
    <checklist-fill-box
      :checklistName="checklistName"
      :columns="columns"
      :rows="finalCheckpoints ? finalCheckpoints : []"
      :showUpload="true"
      :format="this.$route.query.type"
      :ID="this.$route.params.id"
      :checklistStores="checklistStores"
      emptyText="no checkpoint found"
      uploadText="Upload Checkpoints"
      :onlySavedReport="onlySavedReport"
      @show-upload="onUpload"
    >
    </checklist-fill-box>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
// import CheckListFormBox from '../../components/CheckListFormBox.vue';
import _ from 'lodash';
import Vue from 'vue';
import csv from 'csvtojson';
import ChecklistFillBox from '../../components/ChecklistFillBox.vue';

export default {
  name: 'CheckListFillForm',
  components: {
    ChecklistFillBox
  },

  async mounted() {
    this.id = this.$route.params.id;
    if (this.$route.query.type === 'report') {
      this.getUserFilledReport({
        query: {
          id: this.$route.params.id
        }
      });
      await this.getCheckpoints({
        id: this.$route.query.checklistID,
        router
      });
    } else {
      await this.getCheckpoints({
        id: this.id,
        router
      });
    }
  },
  data() {
    return {
      formType: '',
      id: null,
      checklistStores: [],
      checklistName: '',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'rowNum',
          width: '4%'
        },
        {
          type: 'row_num',
          headerText: 'Category',
          class: { ' text-center': true },
          textKey: 'category',
          width: '13%'
        },
        {
          type: 'row_num',
          headerText: 'Subcategory',
          class: { ' text-center': true },
          textKey: 'subCategory',
          width: '13%'
        },
        {
          type: 'row_num',
          headerText: 'Question',
          class: { ' text-center': true },
          textKey: 'questionText',
          width: '25%'
        },

        {
          type: 'image',
          headerText: 'Image Mandatory',
          class: { ' text-center': true },
          textKey: 'isImageMandatory',
          width: '10%'
        },
        {
          type: 'image',
          headerText: 'Image',
          class: { ' text-center': true },
          textKey: 'picture',
          width: '15%'
        },
        {
          type: 'row_num',
          headerText: 'Response',
          class: { ' text-center': true },
          textKey: 'checkpointType',
          width: '20%'
        }
      ],
      rows: [],
      finalCheckpoints: [],
      onlySavedReport: false
    };
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Add New Checklist' : 'Update Checklist';
    },
    ...mapState('checklist', ['loading', 'checklist']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('checklistFill', ['loading', 'fillingCheckpoints', 'userFilledReport', 'checkpointResponse'])
  },
  methods: {
    ...mapActions('checklist', [, 'getOne', 'postOne', 'patchOne']),
    ...mapActions('checklistFill', ['getCheckpoints', 'getUserFilledReport']),
    convertIntoUnderScore(str) {
      return str.split(',').join('\\-');
    },
    convertBackToComma(str) {
      return str.split('\\-').join(',');
    },
    createCsv() {
      let csv = `Category,Sub-Category,Question,Checkpoint-Type,Response(yes/no),Target,Actual,Remarks\n`;
      this.finalCheckpoints.forEach(
        ({ category, subCategory, questionText, checkpointType, booleanAnswer, target, actual, remarks }) => {
          csv += `${category},${subCategory},${this.convertIntoUnderScore(
            questionText
          )},${checkpointType},${booleanAnswer},${target},${actual},${remarks}\n`;
        }
      );
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = `${this.checklistName}-checkpoints.csv`;
      anchor.click();
    },
    async onUpload() {
      await Vue.swal({
        title: 'Upload file',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDenied) {
          this.createCsv();
        }
        if (result.value) {
          if (result.value.name === `${this.checklistName}-checkpoints.csv`) {
            const reader = new FileReader();
            reader.onload = async () => {
              csv({
                noheader: true,
                output: 'csv'
              })
                .fromString(reader.result)
                .then(async csvRow => {
                  const inputs = csvRow.slice(1, csvRow.length).map(i => {
                    const values = i.reduce((acc, curr, index) => {
                      if (index === 0) {
                        acc.category = curr;
                      }
                      if (index === 1) {
                        acc.subCategory = curr;
                      }
                      if (index === 2) {
                        acc.questionText = this.convertBackToComma(curr);
                      }
                      if (index === 3) {
                        acc.checkpointType = curr;
                      }
                      if (index === 4) {
                        const possibleValues = ['Yes', 'No', 'yes', 'no', 'YES', 'NO'];
                        if (possibleValues.includes(curr)) {
                          acc.booleanAnswer = curr.toUpperCase();
                        } else {
                          acc.booleanAnswer = null;
                        }
                      }
                      if (index === 5) {
                        acc.target = curr;
                      }
                      if (index === 6) {
                        acc.actual = curr;
                      }
                      if (index === 7) {
                        acc.remarks = curr;
                      }

                      if (acc.checkpointType === 'BOOLEAN') {
                        acc.target = '';
                        acc.actual = '';
                        // acc.remarks = '';
                      } else if (acc.checkpointType === 'TARGET') {
                        acc.booleanAnswer = '';
                      }
                      return acc;
                    }, {});
                    return values;
                  });
                  this.finalCheckpoints = this.finalCheckpoints.map((item, index) => {
                    return {
                      ...item,
                      category: inputs[index].category,
                      subCategory: inputs[index].subCategory,
                      target: inputs[index].target ? inputs[index].target : '',
                      booleanAnswer: inputs[index].booleanAnswer ? inputs[index].booleanAnswer : '',
                      actual: inputs[index].actual ? inputs[index].actual : '',
                      remarks: inputs[index].remarks ? inputs[index].remarks : ''
                      // checkpointType: inputs[index].checkpointType : ''
                    };
                  });
                });
            };
            reader.readAsBinaryString(result.value);
          } else {
            Vue.swal({
              title: 'Access Denied',
              text: 'Please upload correct file',
              type: 'error',
              confirmButtonText: 'Ok',
              icon: 'error',
              background: this.isDarkMode ? '#464d54' : '#ffffff',
              customClass: {
                title: this.isDarkMode ? 'text-light' : 'text-dark',
                content: this.isDarkMode ? 'text-light' : 'text-dark'
              }
            });
          }
        }
      });
    },
    compareArrays(filledCheckpoint, notFilledCheckpoints) {
      const filledArray = filledCheckpoint.map(i => i?.checkpoint?._id);
      if (filledArray.length === notFilledCheckpoints.length) {
        this.onlySavedReport = true;
      }
      return notFilledCheckpoints
        .filter(item => {
          if (filledArray.includes(item._id)) {
            return false;
          }
          return true;
        })
        .map(checkpoint => {
          return { ...checkpoint, target: '', actual: '', remarks: '', booleanAnswer: '' };
        });
    }
  },
  watch: {
    checkpointResponse(newValue) {
      if (this.$route.query.type === '' || this.$route.query.type === undefined) {
        if (newValue[0]?.report) {
          this.getUserFilledReport({
            query: {
              id: newValue[0]?.report
            }
          });
        }
        this.getCheckpoints({
          id: this.id,
          router
        });
      } else {
        this.getUserFilledReport({
          query: {
            id: this.$route.params.id
          }
        });
        this.getCheckpoints({
          id: this.$route.query.checklistID,
          router
        });
      }
    },
    userFilledReport(newValue = []) {
      this.checklistName = newValue?.checklistName;
      if (newValue !== undefined) {
        if (newValue?.submissions?.length === this.fillingCheckpoints?.length) {
          this.onlySavedReport = true;
        }
        this.rows = newValue?.submissions?.map((item, index) => {
          return {
            ...item,
            rowNum: index + 1,
            actual: item.actual !== null ? item.actual : '',
            booleanAnswer: item.booleanAnswer !== null ? item.booleanAnswer : '',
            target: item.target !== null ? item.target : '',
            remarks: item.remarks !== null ? item.remarks : '',
            isImageMandatory: item?.checkpoint?.isImageMandatory,
            weightage: item?.checkpoint?.weightage
          };
        });
      }
    },
    rows(newValue) {
      if (newValue !== undefined && this.fillingCheckpoints !== undefined) {
        this.finalCheckpoints = [...newValue, ...this.compareArrays(this.rows, this.fillingCheckpoints)];
      }
    },
    fillingCheckpoints(newValue) {
      this.checklistName = newValue[0]?.checkListName;
      if (this.$route.query.type === '' || this.$route.query.type === undefined) {
        this.finalCheckpoints = newValue.map(checkpoint => {
          return { ...checkpoint, target: '', actual: '', remarks: '', booleanAnswer: '' };
        });
      }
    },
    checklist(value) {
      const stores = value.storeids;
      this.checklistStores = stores.length > 0 ? stores : [];
    }
  }
};
</script>
